'use client'

import classnames from 'classnames'
import styles from './BlogContent.module.scss'
import BlogContentRichText from '@/sections/BlogContent/BlogContentRichText/BlogContentRichText'
import SanityImage from '@/components/SanityImage/SanityImage'
// import { getFormattedDateString } from '@/utils'
import FadeIn from '@/components/FadeIn/FadeIn'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import BlogTitleRenderer from '@/components/BlogTitleRenderer/BlogTitleRenderer'
import { PortableText } from 'next-sanity'
// import Link from '@/components/Link/Link'
// import { BLOG_SLUG, DOC_TYPES } from '@/data'

const BlogContent = ({
  title,
  category,
  subtitle,
  image,
  // author,
  publishedDate,
  content,
  data,
}: BlogContent) => {
  if (!title || !category || !image || !publishedDate || !content) return null

  return (
    <div className={classnames(styles.BlogContent)}>
      <div className={styles.inner}>
        <div className={styles.headerContent}>
          <FadeIn>
            <p className={styles.category}>
              {category}
              {/* <Link
                link={{
                  label: category,
                  linkType: 'internal',
                  link: {
                    _type: DOC_TYPES.PAGE,
                    slug: BLOG_SLUG,
                  },
                }}
                queryParamString={`?filter=${encodeURIComponent(category)}`}
              /> */}
            </p>
          </FadeIn>
          <AnimatedTextLines querySelector="h1">
            <h1 className={styles.heading}>
              <BlogTitleRenderer {...data} />
            </h1>
          </AnimatedTextLines>
          {!!subtitle?.length && (
            <FadeIn>
              <div className={styles.subtitle}>
                <PortableText
                  // eslint-disable-next-line
                  value={subtitle as any}
                />
              </div>
            </FadeIn>
          )}
          <FadeIn className={styles.imageContainer}>
            <SanityImage
              className={styles.image}
              image={image}
              breakpoints={{
                laptop: {
                  image,
                  width: 1300,
                },
                xs: {
                  image,
                  width: 800,
                },
              }}
            />
          </FadeIn>
        </div>
        <div className={styles.richTextContent}>
          {/* <div className={styles.authorContent}>
            {author && (
              <>
                <span className={styles.authorContent__author}>By {author.fullName}</span>
                <br />
              </>
            )}
            <span className={styles.authorContent__publishedDate}>{getFormattedDateString(publishedDate)}</span>
          </div> */}
          <BlogContentRichText content={content} />
        </div>
      </div>
    </div>
  )
}

BlogContent.displayName = 'BlogContent'

export default BlogContent
