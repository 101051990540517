'use client'

const HomepageHeroPaths = () => {
  return (
    <>
      <path
        d="M1169.89 12.3101L1314.03 -131.83L1255.75 -190.11L1111.61 -45.9699C1132.04 -27.5699 1151.49 -8.11987 1169.89 12.3101Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M62.59 -45.9698L-63.72 -172.28L-122 -114L4.31 12.3102C22.71 -8.11979 42.16 -27.5698 62.59 -45.9698Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M893.27 1168.32L924.87 1244.6C993.16 1211.95 1055.99 1169.71 1111.61 1119.61L1053.25 1061.25C1005.35 1103.86 951.56 1140 893.27 1168.32Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1218.59 230.65L1294.87 199.05C1262.22 130.76 1219.98 67.9301 1169.88 12.3101L1111.52 70.6701C1154.13 118.57 1190.27 172.36 1218.59 230.65Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1053.25 12.3901L1111.61 -45.97C1055.99 -96.06 993.16 -138.31 924.87 -170.96L893.27 -94.6799C951.56 -66.3699 1005.34 -30.2199 1053.25 12.3901Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1111.53 1002.98L1169.89 1061.34C1219.98 1005.72 1262.23 942.89 1294.88 874.6L1218.6 843C1190.29 901.29 1154.14 955.07 1111.53 1002.98Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M-44.4002 843L-120.68 874.6C-88.0302 942.89 -45.7902 1005.72 4.30981 1061.34L62.6698 1002.98C20.0598 955.08 -16.0802 901.29 -44.4002 843Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M62.6698 70.6701L4.30981 12.3101C-45.7802 67.9301 -88.0302 130.76 -120.68 199.05L-44.4002 230.65C-16.0902 172.36 20.0598 118.58 62.6698 70.6701Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M120.95 1061.25L62.5898 1119.61C118.21 1169.7 181.04 1211.95 249.33 1244.6L280.93 1168.32C222.64 1140.01 168.86 1103.86 120.95 1061.25Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M280.92 -94.6702L249.32 -170.95C181.03 -138.3 118.2 -96.0602 62.5801 -45.9602L120.94 12.3998C168.84 -30.2102 222.63 -66.3502 280.92 -94.6702Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1111.53 70.6698L1169.89 12.3098C1151.49 -8.12022 1132.04 -27.5702 1111.61 -45.9702L1053.25 12.3898C1073.79 30.6598 1093.25 50.1198 1111.53 70.6698Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M62.67 1002.98L4.31006 1061.34C22.7101 1081.77 42.1601 1101.22 62.5901 1119.62L120.95 1061.26C100.41 1042.99 80.95 1023.53 62.67 1002.98Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1053.25 1061.25L1111.61 1119.61C1132.04 1101.21 1151.49 1081.76 1169.89 1061.33L1111.53 1002.97C1093.26 1023.51 1073.8 1042.97 1053.25 1061.25Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M120.95 12.3898L62.5901 -45.9702C42.1601 -27.5702 22.7101 -8.12022 4.31006 12.3098L62.67 70.6698C80.94 50.1298 100.4 30.6698 120.95 12.3898Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1204.98 495.62H1287.55C1283.74 429.83 1270.87 366.48 1250.16 306.78L1173.86 338.38C1190.72 388.25 1201.39 440.96 1204.98 495.61V495.62Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M0.339935 338.38L-75.9601 306.77C-96.6701 366.47 -109.54 429.82 -113.35 495.61H-30.7801C-27.1901 440.96 -16.5201 388.24 0.339935 338.38Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M994.86 70.7901L1053.25 12.4001C1005.35 -30.2099 951.56 -66.3499 893.27 -94.6699L861.66 -18.3599C909.97 5.58011 954.7 35.6301 994.85 70.7901H994.86Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M312.53 -18.3702L280.92 -94.6802C222.63 -66.3702 168.85 -30.2202 120.94 12.3898L179.33 70.7798C219.48 35.6198 264.21 5.55982 312.52 -18.3702H312.53Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M121.06 129.06L62.6701 70.6699C20.0601 118.57 -16.0799 172.36 -44.3999 230.65L31.9101 262.26C55.8501 213.95 85.9001 169.22 121.06 129.07V129.06Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1142.29 262.25L1218.6 230.64C1190.29 172.35 1154.14 118.57 1111.53 70.6602L1053.14 129.05C1088.3 169.2 1118.36 213.93 1142.29 262.24V262.25Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M861.67 1092.01L893.28 1168.32C951.57 1140.01 1005.35 1103.86 1053.26 1061.25L994.87 1002.86C954.72 1038.02 909.99 1068.08 861.68 1092.01H861.67Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1173.86 735.26L1250.16 766.86C1270.87 707.17 1283.74 643.81 1287.55 578.02H1204.98C1201.39 632.67 1190.72 685.39 1173.86 735.25V735.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1053.13 944.58L1111.52 1002.97C1154.13 955.07 1190.27 901.28 1218.59 842.99L1142.28 811.38C1118.34 859.69 1088.29 904.42 1053.13 944.57V944.58Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M31.9101 811.39L-44.3999 843C-16.0899 901.29 20.0601 955.07 62.6701 1002.98L121.06 944.59C85.9001 904.44 55.8401 859.71 31.9101 811.4V811.39Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M-30.7801 578.03H-113.35C-109.54 643.82 -96.6701 707.17 -75.9601 766.87L0.339935 735.26C-16.5201 685.39 -27.1901 632.68 -30.7801 578.03Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M179.34 1002.86L120.95 1061.25C168.85 1103.86 222.64 1140 280.93 1168.32L312.54 1092.01C264.23 1068.07 219.5 1038.02 179.35 1002.86H179.34Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1142.29 811.39L1218.6 843C1230.55 818.39 1241.11 792.98 1250.17 766.87L1173.87 735.27C1165.01 761.48 1154.44 786.9 1142.3 811.4L1142.29 811.39Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M31.9102 262.25L-44.3998 230.64C-56.3498 255.25 -66.9097 280.66 -75.9697 306.77L0.330261 338.38C9.19026 312.17 19.7603 286.75 31.9003 262.25H31.9102Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M121.06 944.58L62.6699 1002.97C80.9399 1023.51 100.4 1042.97 120.95 1061.25L179.34 1002.86C158.65 984.74 139.18 965.27 121.06 944.58Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1053.13 129.06L1111.52 70.6701C1093.25 50.1301 1073.79 30.6701 1053.24 12.3901L994.85 70.7801C1015.54 88.9001 1035.01 108.37 1053.13 129.06Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M179.34 70.7899L120.95 12.3999C100.41 30.6699 80.9499 50.1299 62.6699 70.6799L121.06 129.07C139.18 108.38 158.65 88.9099 179.34 70.7899Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M994.86 1002.86L1053.25 1061.25C1073.79 1042.98 1093.25 1023.52 1111.53 1002.97L1053.14 944.58C1035.02 965.27 1015.55 984.74 994.86 1002.86Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1173.86 338.38L1250.16 306.78C1241.1 280.67 1230.55 255.26 1218.59 230.65L1142.28 262.26C1154.42 286.76 1164.99 312.18 1173.85 338.39L1173.86 338.38Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M0.340088 735.26L-75.96 766.87C-66.9 792.98 -56.35 818.39 -44.39 843L31.92 811.39C19.78 786.89 9.21004 761.47 0.350037 735.26H0.340088Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M76.6998 370.01L0.339783 338.38C-16.5202 388.25 -27.1903 440.96 -30.7803 495.61H51.8398C55.1398 452.09 63.6398 410.01 76.7098 370.01H76.6998Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M51.83 578.03H-30.79C-27.2 632.68 -16.53 685.4 0.329956 735.26L76.6899 703.63C63.6199 663.62 55.1299 621.55 51.8199 578.03H51.83Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1065.93 293.88L1142.29 262.25C1118.35 213.94 1088.3 169.21 1053.14 129.06L994.72 187.48C1022.47 219.83 1046.44 255.52 1065.94 293.87L1065.93 293.88Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M753.91 26.4302L785.54 -49.9298C735.67 -66.7898 682.96 -77.4598 628.31 -81.0498V1.57019C671.83 4.87019 713.91 13.3702 753.91 26.4402V26.4302Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 1.55994V-81.0601C491.24 -77.4701 438.52 -66.8001 388.66 -49.9401L420.29 26.42C460.3 13.35 502.37 4.85993 545.89 1.54993V1.55994Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M179.49 187.49L121.07 129.07C85.9099 169.22 55.8499 213.95 31.9199 262.26L108.28 293.89C127.78 255.54 151.74 219.85 179.5 187.5L179.49 187.49Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M344.16 57.9899L312.53 -18.3701C264.22 5.56988 219.49 35.6199 179.34 70.7799L237.76 129.2C270.11 101.45 305.8 77.4799 344.15 57.9799L344.16 57.9899Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M994.71 886.16L1053.13 944.58C1088.29 904.43 1118.35 859.7 1142.28 811.39L1065.92 779.76C1046.42 818.11 1022.46 853.8 994.7 886.15L994.71 886.16Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M830.04 1015.65L861.67 1092.01C909.98 1068.07 954.71 1038.02 994.86 1002.86L936.44 944.44C904.09 972.19 868.4 996.16 830.05 1015.66L830.04 1015.65Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M936.43 129.21L994.85 70.7901C954.7 35.6301 909.97 5.57014 861.66 -18.3599L830.03 58.0001C868.38 77.5001 904.07 101.46 936.42 129.22L936.43 129.21Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1097.5 370.01C1110.57 410.02 1119.06 452.09 1122.37 495.61H1204.99C1201.4 440.96 1190.73 388.24 1173.87 338.38L1097.51 370.01H1097.5Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M108.27 779.76L31.9102 811.39C55.8502 859.7 85.9002 904.43 121.06 944.58L179.48 886.16C151.73 853.81 127.76 818.12 108.26 779.77L108.27 779.76Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1097.5 703.63L1173.86 735.26C1190.72 685.39 1201.39 632.68 1204.98 578.03H1122.36C1119.06 621.55 1110.56 663.63 1097.49 703.63H1097.5Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 1072.09V1154.71C682.96 1151.12 735.68 1140.45 785.54 1123.59L753.91 1047.23C713.9 1060.3 671.83 1068.79 628.31 1072.1V1072.09Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M420.29 1047.22L388.66 1123.58C438.53 1140.44 491.24 1151.11 545.89 1154.7V1072.08C502.37 1068.78 460.29 1060.28 420.29 1047.21V1047.22Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M237.76 944.43L179.34 1002.85C219.49 1038.01 264.22 1068.07 312.53 1092L344.16 1015.64C305.81 996.14 270.12 972.18 237.77 944.42L237.76 944.43Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 9.15527e-05C600.96 9.15527e-05 614.71 0.530089 628.31 1.56009V-81.0599C614.69 -81.9499 600.95 -82.4199 587.1 -82.4199C573.25 -82.4199 559.51 -81.9599 545.89 -81.0599V1.56009C559.49 0.530089 573.23 9.15527e-05 587.1 9.15527e-05Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M344.16 1015.65L312.53 1092.01C337.03 1104.15 362.45 1114.72 388.66 1123.58L420.29 1047.22C393.96 1038.62 368.52 1028.04 344.16 1015.65Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M830.04 57.9901L861.67 -18.3699C837.17 -30.5099 811.75 -41.0799 785.54 -49.9399L753.91 26.4201C780.24 35.0201 805.68 45.6001 830.04 57.9901Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1123.92 536.82C1123.92 550.68 1123.39 564.43 1122.36 578.03H1204.98C1205.88 564.41 1206.34 550.67 1206.34 536.82C1206.34 522.97 1205.88 509.23 1204.98 495.61H1122.36C1123.39 509.21 1123.92 522.95 1123.92 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M50.2701 536.82C50.2701 522.96 50.8001 509.21 51.8301 495.61H-30.7899C-31.6799 509.23 -32.1499 522.97 -32.1499 536.82C-32.1499 550.67 -31.6899 564.41 -30.7899 578.03H51.8301C50.8001 564.43 50.2701 550.69 50.2701 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M108.27 293.88L31.9098 262.25C19.7698 286.75 9.19984 312.17 0.339844 338.38L76.6998 370.01C85.2998 343.68 95.8798 318.24 108.27 293.88Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1065.93 779.76L1142.29 811.39C1154.43 786.89 1165 761.47 1173.86 735.26L1097.5 703.63C1088.9 729.96 1078.32 755.4 1065.93 779.76Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M179.49 886.16L121.07 944.58C139.19 965.27 158.66 984.74 179.35 1002.86L237.77 944.44C216.89 926.53 197.4 907.04 179.49 886.16Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M994.71 187.49L1053.13 129.07C1035.01 108.38 1015.54 88.91 994.85 70.79L936.43 129.21C957.31 147.12 976.8 166.61 994.71 187.49Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M936.43 944.43L994.85 1002.85C1015.54 984.73 1035.01 965.26 1053.13 944.57L994.71 886.15C976.8 907.03 957.31 926.52 936.43 944.43Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M237.76 129.21L179.34 70.79C158.65 88.91 139.18 108.38 121.06 129.07L179.48 187.49C197.39 166.61 216.88 147.12 237.76 129.21Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M76.6998 703.63L0.339844 735.26C9.19984 761.47 19.7698 786.89 31.9098 811.39L108.27 779.76C95.8798 755.4 85.2998 729.96 76.6998 703.63Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1097.5 370.01L1173.86 338.38C1165 312.17 1154.43 286.75 1142.29 262.25L1065.93 293.88C1078.32 318.24 1088.9 343.68 1097.5 370.01Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M420.29 26.4298L388.66 -49.9302C362.45 -41.0702 337.03 -30.5002 312.53 -18.3602L344.16 57.9998C368.52 45.6098 393.96 35.0298 420.29 26.4298Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M753.91 1047.22L785.54 1123.58C811.75 1114.72 837.17 1104.15 861.67 1092.01L830.04 1015.65C805.68 1028.04 780.24 1038.62 753.91 1047.22Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M375.81 134.42L344.15 58C305.8 77.5 270.11 101.46 237.76 129.22L296.24 187.7C320.72 167.28 347.37 149.39 375.81 134.43V134.42Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M153.13 401.67L76.7001 370.01C63.6301 410.02 55.1401 452.09 51.8301 495.61H134.53C137.44 463.19 143.76 431.76 153.12 401.66L153.13 401.67Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M722.25 102.85L753.91 26.4198C713.9 13.3498 671.83 4.8598 628.31 1.5498V84.2498C660.73 87.1598 692.16 93.4798 722.26 102.84L722.25 102.85Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M237.97 245.97L179.49 187.49C151.74 219.84 127.77 255.53 108.27 293.88L184.69 325.54C199.65 297.1 217.55 270.45 237.96 245.97H237.97Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M134.53 578.03H51.8301C55.1301 621.55 63.6301 663.63 76.7001 703.63L153.13 671.97C143.77 641.88 137.45 610.44 134.54 578.02L134.53 578.03Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M877.95 187.69L936.43 129.21C904.08 101.46 868.39 77.4902 830.04 57.9902L798.38 134.41C826.82 149.37 853.47 167.27 877.95 187.68V187.69Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M989.5 325.54L1065.93 293.88C1046.43 255.53 1022.47 219.84 994.71 187.49L936.23 245.97C956.65 270.45 974.54 297.1 989.5 325.54Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 84.2601V1.56006C502.37 4.86006 460.29 13.3601 420.29 26.4301L451.95 102.86C482.04 93.5 513.48 87.1801 545.9 84.2701L545.89 84.2601Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M451.94 970.79L420.28 1047.22C460.29 1060.29 502.36 1068.78 545.88 1072.09V989.39C513.46 986.48 482.03 980.16 451.93 970.8L451.94 970.79Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M798.38 939.23L830.04 1015.65C868.39 996.15 904.08 972.19 936.43 944.43L877.95 885.95C853.47 906.37 826.82 924.26 798.38 939.22V939.23Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 989.39V1072.09C671.83 1068.79 713.91 1060.29 753.91 1047.22L722.25 970.79C692.16 980.15 660.72 986.47 628.3 989.38L628.31 989.39Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M936.23 827.68L994.71 886.16C1022.46 853.81 1046.43 818.12 1065.93 779.77L989.51 748.11C974.55 776.55 956.65 803.2 936.24 827.68H936.23Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1021.07 671.98L1097.5 703.64C1110.57 663.63 1119.06 621.56 1122.37 578.04H1039.67C1036.76 610.46 1030.44 641.89 1021.08 671.99L1021.07 671.98Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1039.66 495.62H1122.36C1119.06 452.1 1110.56 410.02 1097.49 370.02L1021.06 401.68C1030.42 431.77 1036.74 463.21 1039.65 495.63L1039.66 495.62Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M184.7 748.11L108.28 779.77C127.78 818.12 151.74 853.81 179.5 886.16L237.98 827.68C217.56 803.2 199.67 776.55 184.71 748.11H184.7Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M296.24 885.96L237.76 944.44C270.11 972.19 305.8 996.16 344.15 1015.66L375.81 939.24C347.37 924.28 320.72 906.38 296.24 885.97V885.96Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 991.23C573.21 991.23 559.47 990.6 545.89 989.38V1072.08C559.49 1073.11 573.23 1073.64 587.1 1073.64C600.97 1073.64 614.71 1073.11 628.31 1072.08V989.38C614.74 990.6 600.99 991.23 587.1 991.23Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 82.41C600.99 82.41 614.73 83.04 628.31 84.26V1.56C614.71 0.529998 600.97 0 587.1 0C573.23 0 559.49 0.529998 545.89 1.56V84.26C559.46 83.04 573.21 82.41 587.1 82.41Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M798.38 134.42L830.04 58.0002C805.68 45.6102 780.24 35.0302 753.91 26.4302L722.25 102.86C748.75 111.1 774.21 121.71 798.38 134.43V134.42Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M375.81 939.23L344.15 1015.65C368.51 1028.04 393.95 1038.62 420.28 1047.22L451.94 970.79C425.44 962.55 399.98 951.94 375.81 939.22V939.23Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M1041.51 536.82C1041.51 550.71 1040.88 564.45 1039.66 578.03H1122.36C1123.39 564.43 1123.92 550.69 1123.92 536.82C1123.92 522.95 1123.39 509.21 1122.36 495.61H1039.66C1040.88 509.18 1041.51 522.93 1041.51 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M132.69 536.82C132.69 522.93 133.32 509.19 134.54 495.61H51.8403C50.8103 509.21 50.2803 522.95 50.2803 536.82C50.2803 550.69 50.8103 564.43 51.8403 578.03H134.54C133.32 564.46 132.69 550.71 132.69 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M989.5 748.11L1065.92 779.77C1078.31 755.41 1088.89 729.97 1097.49 703.64L1021.06 671.98C1012.82 698.48 1002.21 723.94 989.49 748.11H989.5Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M184.7 325.54L108.28 293.88C95.89 318.24 85.31 343.68 76.71 370.01L153.14 401.67C161.38 375.17 171.99 349.71 184.71 325.54H184.7Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M237.97 827.68L179.49 886.16C197.4 907.04 216.89 926.53 237.77 944.44L296.25 885.96C275.11 868.33 255.6 848.82 237.97 827.68Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M936.23 245.97L994.71 187.49C976.8 166.61 957.31 147.12 936.43 129.21L877.95 187.69C899.09 205.32 918.6 224.83 936.23 245.97Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M296.24 187.69L237.76 129.21C216.88 147.12 197.39 166.61 179.48 187.49L237.96 245.97C255.59 224.83 275.1 205.32 296.24 187.69Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M877.95 885.96L936.43 944.44C957.31 926.53 976.8 907.04 994.71 886.16L936.23 827.68C918.6 848.82 899.09 868.33 877.95 885.96Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M989.5 325.54C1002.22 349.71 1012.82 375.17 1021.07 401.67L1097.5 370.01C1088.9 343.68 1078.32 318.24 1065.93 293.88L989.5 325.54Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M153.13 671.98L76.7002 703.64C85.3002 729.97 95.8802 755.41 108.27 779.77L184.69 748.11C171.97 723.94 161.37 698.48 153.12 671.98H153.13Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M722.25 970.79L753.91 1047.22C780.24 1038.62 805.68 1028.04 830.04 1015.65L798.38 939.23C774.21 951.95 748.75 962.55 722.25 970.8V970.79Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M451.94 102.85L420.28 26.4199C393.95 35.0199 368.51 45.5999 344.15 57.9899L375.81 134.41C399.98 121.69 425.44 111.09 451.94 102.84V102.85Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M229.68 433.38L153.12 401.67C143.76 431.76 137.44 463.2 134.53 495.62H217.36C219.71 474.28 223.87 453.48 229.68 433.38Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M217.37 578.03H134.54C137.45 610.45 143.77 641.88 153.13 671.98L229.69 640.27C223.88 620.17 219.73 599.38 217.37 578.03Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M407.52 210.97L375.81 134.42C347.37 149.38 320.72 167.28 296.24 187.69L354.81 246.26C371.29 233.07 388.92 221.25 407.52 210.97Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M296.53 304.53L237.96 245.96C217.54 270.44 199.65 297.09 184.69 325.53L261.24 357.24C271.51 338.64 283.33 321.01 296.53 304.53Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M819.39 246.26L877.96 187.69C853.48 167.27 826.83 149.38 798.39 134.42L766.68 210.97C785.28 221.24 802.91 233.06 819.39 246.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 167.09V84.2598C513.47 87.1698 482.04 93.4898 451.94 102.85L483.65 179.41C503.75 173.6 524.54 169.45 545.89 167.09Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M690.54 179.41L722.25 102.85C692.16 93.4898 660.72 87.1698 628.3 84.2598V167.09C649.64 169.44 670.44 173.6 690.54 179.41Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M261.25 716.4L184.7 748.11C199.66 776.55 217.56 803.2 237.97 827.68L296.54 769.11C283.35 752.63 271.53 735 261.25 716.4Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M483.65 894.24L451.94 970.8C482.03 980.16 513.47 986.48 545.89 989.39V906.56C524.55 904.21 503.75 900.05 483.65 894.24Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M956.83 495.62H1039.66C1036.75 463.2 1030.43 431.77 1021.07 401.67L944.51 433.38C950.32 453.48 954.47 474.27 956.83 495.62Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 906.55V989.38C660.73 986.47 692.16 980.15 722.26 970.79L690.55 894.23C670.45 900.04 649.66 904.19 628.31 906.55Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M877.66 304.53C890.85 321.01 902.67 338.64 912.95 357.24L989.5 325.53C974.54 297.09 956.64 270.44 936.23 245.96L877.66 304.53Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M944.51 640.27L1021.07 671.98C1030.43 641.89 1036.75 610.45 1039.66 578.03H956.83C954.48 599.37 950.32 620.17 944.51 640.27Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M877.66 769.11L936.23 827.68C956.65 803.2 974.54 776.55 989.5 748.11L912.95 716.4C902.68 735 890.86 752.63 877.66 769.11Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M766.67 862.67L798.38 939.22C826.82 924.26 853.47 906.36 877.95 885.95L819.38 827.38C802.9 840.57 785.27 852.39 766.67 862.67Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M354.81 827.39L296.24 885.96C320.72 906.38 347.37 924.27 375.81 939.23L407.52 862.68C388.92 852.41 371.29 840.59 354.81 827.39Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 164.83C601.03 164.83 614.78 165.6 628.31 167.09V84.2602C614.74 83.0402 600.99 82.4102 587.1 82.4102C573.21 82.4102 559.47 83.0402 545.89 84.2602V167.09C559.42 165.6 573.17 164.83 587.1 164.83Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 908.82C573.17 908.82 559.42 908.05 545.89 906.56V989.39C559.46 990.61 573.21 991.24 587.1 991.24C600.99 991.24 614.73 990.61 628.31 989.39V906.56C614.78 908.05 601.03 908.82 587.1 908.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M766.67 210.97L798.38 134.42C774.21 121.7 748.75 111.1 722.25 102.85L690.54 179.41C717.28 187.14 742.78 197.78 766.67 210.97Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M407.52 862.67L375.81 939.22C399.98 951.94 425.44 962.54 451.94 970.79L483.65 894.23C456.91 886.5 431.41 875.86 407.52 862.67Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M215.1 536.82C215.1 522.89 215.87 509.14 217.36 495.61H134.53C133.31 509.18 132.68 522.93 132.68 536.82C132.68 550.71 133.31 564.45 134.53 578.03H217.36C215.87 564.5 215.1 550.75 215.1 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M959.09 536.82C959.09 550.75 958.32 564.5 956.83 578.03H1039.66C1040.88 564.46 1041.51 550.71 1041.51 536.82C1041.51 522.93 1040.88 509.19 1039.66 495.61H956.83C958.32 509.14 959.09 522.89 959.09 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M912.95 716.4L989.5 748.11C1002.22 723.94 1012.82 698.48 1021.07 671.98L944.51 640.27C936.78 667.01 926.14 692.51 912.95 716.4Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M261.25 357.25L184.7 325.54C171.98 349.71 161.38 375.17 153.13 401.67L229.69 433.38C237.42 406.64 248.06 381.14 261.25 357.25Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M296.53 769.11L237.96 827.68C255.59 848.82 275.1 868.33 296.24 885.96L354.81 827.39C333.3 810.17 313.75 790.62 296.53 769.11Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M877.66 304.53L936.23 245.96C918.6 224.82 899.09 205.31 877.95 187.68L819.38 246.25C840.89 263.47 860.44 283.02 877.66 304.53Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M819.39 827.39L877.96 885.96C899.1 868.33 918.61 848.82 936.24 827.68L877.67 769.11C860.45 790.62 840.9 810.17 819.39 827.39Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M354.81 246.26L296.24 187.69C275.1 205.32 255.59 224.83 237.96 245.97L296.53 304.54C313.75 283.03 333.3 263.48 354.81 246.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M229.68 640.27L153.12 671.98C161.36 698.48 171.97 723.94 184.69 748.11L261.24 716.4C248.04 692.51 237.4 667.01 229.68 640.27Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M944.51 433.38L1021.07 401.67C1012.83 375.17 1002.22 349.71 989.5 325.54L912.95 357.25C926.15 381.14 936.79 406.64 944.51 433.38Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M483.65 179.41L451.94 102.85C425.44 111.09 399.98 121.7 375.81 134.42L407.52 210.97C431.41 197.77 456.91 187.13 483.65 179.41Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M690.54 894.24L722.25 970.8C748.75 962.56 774.21 951.95 798.38 939.23L766.67 862.68C742.78 875.88 717.28 886.52 690.54 894.24Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M338.03 684.59L261.25 716.39C271.52 734.99 283.34 752.62 296.54 769.1L355.28 710.36C349.09 702.1 343.34 693.49 338.04 684.58L338.03 684.59Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M306.46 465.18L229.68 433.38C223.87 453.48 219.72 474.27 217.36 495.62H300.44C301.91 485.29 303.92 475.13 306.45 465.19L306.46 465.18Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M355.27 363.27L296.53 304.53C283.34 321.01 271.52 338.64 261.24 357.24L338.02 389.04C343.32 380.13 349.07 371.52 355.26 363.26L355.27 363.27Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 250.18V167.1C524.55 169.45 503.75 173.61 483.65 179.42L515.45 256.2C525.4 253.67 535.56 251.66 545.88 250.19L545.89 250.18Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M300.45 578.03H217.37C219.72 599.37 223.88 620.17 229.69 640.27L306.47 608.47C303.94 598.52 301.93 588.36 300.46 578.04L300.45 578.03Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M658.74 256.18L690.54 179.4C670.44 173.59 649.65 169.44 628.3 167.08V250.16C638.63 251.63 648.79 253.64 658.73 256.17L658.74 256.18Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M760.65 305L819.39 246.26C802.91 233.07 785.28 221.25 766.68 210.97L734.88 287.75C743.79 293.05 752.4 298.8 760.66 304.99L760.65 305Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M515.46 817.46L483.66 894.24C503.76 900.05 524.55 904.2 545.9 906.56V823.48C535.57 822.01 525.41 820 515.47 817.47L515.46 817.46Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M836.17 389.05L912.95 357.25C902.68 338.65 890.86 321.02 877.66 304.54L818.92 363.28C825.11 371.54 830.86 380.15 836.16 389.06L836.17 389.05Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M867.74 608.46L944.52 640.26C950.33 620.16 954.48 599.37 956.84 578.02H873.76C872.29 588.35 870.28 598.51 867.75 608.45L867.74 608.46Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M413.55 768.65L354.81 827.39C371.29 840.58 388.92 852.4 407.52 862.68L439.32 785.9C430.41 780.6 421.8 774.85 413.54 768.66L413.55 768.65Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M439.33 287.76L407.53 210.98C388.93 221.25 371.3 233.07 354.82 246.27L413.56 305.01C421.82 298.82 430.43 293.07 439.34 287.77L439.33 287.76Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M873.75 495.62H956.83C954.48 474.28 950.32 453.48 944.51 433.38L867.73 465.18C870.26 475.13 872.27 485.29 873.74 495.61L873.75 495.62Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M734.87 785.89L766.67 862.67C785.27 852.4 802.9 840.58 819.38 827.38L760.64 768.64C752.38 774.83 743.77 780.58 734.86 785.88L734.87 785.89Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 823.47V906.55C649.65 904.2 670.45 900.04 690.55 894.23L658.75 817.45C648.8 819.98 638.64 821.99 628.32 823.46L628.31 823.47Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M818.93 710.37L877.67 769.11C890.86 752.63 902.68 735 912.96 716.4L836.18 684.6C830.88 693.51 825.13 702.12 818.94 710.38L818.93 710.37Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 247.24C601.09 247.24 614.85 248.25 628.31 250.17V167.09C614.78 165.6 601.03 164.83 587.1 164.83C573.17 164.83 559.42 165.6 545.89 167.09V250.17C559.35 248.25 573.11 247.24 587.1 247.24Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M587.1 826.4C573.11 826.4 559.35 825.39 545.89 823.47V906.55C559.42 908.04 573.17 908.81 587.1 908.81C601.03 908.81 614.78 908.04 628.31 906.55V823.47C614.85 825.39 601.09 826.4 587.1 826.4Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M439.33 785.89L407.53 862.67C431.42 875.87 456.92 886.51 483.66 894.23L515.46 817.45C488.35 810.55 462.78 799.82 439.33 785.88V785.89Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M734.87 287.76L766.67 210.98C742.78 197.78 717.28 187.14 690.54 179.42L658.74 256.2C685.85 263.1 711.42 273.83 734.87 287.77V287.76Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M876.68 536.82C876.68 550.81 875.67 564.57 873.75 578.03H956.83C958.32 564.5 959.09 550.75 959.09 536.82C959.09 522.89 958.32 509.14 956.83 495.61H873.75C875.67 509.07 876.68 522.83 876.68 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M297.52 536.82C297.52 522.83 298.53 509.07 300.45 495.61H217.37C215.88 509.14 215.11 522.89 215.11 536.82C215.11 550.75 215.88 564.5 217.37 578.03H300.45C298.53 564.57 297.52 550.81 297.52 536.82Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M338.03 389.05L261.25 357.25C248.05 381.14 237.41 406.64 229.69 433.38L306.47 465.18C313.37 438.07 324.1 412.5 338.04 389.05H338.03Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M836.17 684.59L912.95 716.39C926.15 692.5 936.79 667 944.51 640.26L867.73 608.46C860.83 635.57 850.1 661.14 836.16 684.59H836.17Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M819.39 246.26L760.65 305C782.73 321.56 802.37 341.19 818.93 363.28L877.67 304.54C860.45 283.03 840.9 263.48 819.39 246.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M355.27 710.37L296.53 769.11C313.75 790.62 333.3 810.17 354.81 827.39L413.55 768.65C391.47 752.09 371.83 732.46 355.27 710.37Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M760.65 768.65L819.39 827.39C840.9 810.17 860.45 790.62 877.67 769.11L818.93 710.37C802.37 732.45 782.74 752.09 760.65 768.65Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M413.55 305L354.81 246.26C333.3 263.48 313.75 283.03 296.53 304.54L355.27 363.28C371.83 341.2 391.46 321.56 413.55 305Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M306.46 608.46L229.68 640.26C237.41 667 248.05 692.5 261.24 716.39L338.02 684.59C324.08 661.14 313.35 635.57 306.45 608.46H306.46Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M867.74 465.18L944.52 433.38C936.79 406.64 926.15 381.14 912.96 357.25L836.18 389.05C850.12 412.5 860.85 438.07 867.75 465.18H867.74Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M658.74 817.46L690.54 894.24C717.28 886.51 742.78 875.87 766.67 862.68L734.87 785.9C711.42 799.84 685.85 810.57 658.74 817.47V817.46Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M515.46 256.18L483.66 179.4C456.92 187.13 431.42 197.77 407.53 210.96L439.33 287.74C462.78 273.8 488.35 263.07 515.46 256.17V256.18Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 329.66V250.18C535.56 251.65 525.4 253.66 515.46 256.19L545.89 329.67V329.66Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M338.03 684.59C343.33 693.5 349.08 702.11 355.27 710.37L411.47 654.17L338.03 684.59Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 329.66L658.74 256.18C648.79 253.65 638.63 251.64 628.31 250.17V329.65V329.66Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M469.75 361.2L439.33 287.76C430.42 293.06 421.81 298.81 413.55 305L469.75 361.2Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M411.47 419.47L355.27 363.27C349.08 371.53 343.33 380.14 338.03 389.05L411.47 419.47Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M379.93 578.03H300.45C301.92 588.36 303.93 598.52 306.46 608.46L379.94 578.03H379.93Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M306.46 465.18C303.93 475.13 301.92 485.29 300.45 495.61H379.93L306.45 465.18H306.46Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M469.75 712.45L413.55 768.65C421.81 774.84 430.42 780.59 439.33 785.89L469.75 712.45Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M867.74 608.46C870.27 598.51 872.28 588.35 873.75 578.03H794.27L867.75 608.46H867.74Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M734.87 785.89C743.78 780.59 752.39 774.84 760.65 768.65L704.45 712.45L734.87 785.89Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M762.72 419.47L836.16 389.05C830.86 380.14 825.11 371.53 818.92 363.27L762.72 419.47Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M873.75 495.62C872.28 485.29 870.27 475.13 867.74 465.19L794.26 495.62H873.74H873.75Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M762.72 654.17L818.92 710.37C825.11 702.11 830.86 693.5 836.16 684.59L762.72 654.17Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 743.99L515.46 817.47C525.41 820 535.57 822.01 545.89 823.48V744V743.99Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 743.99V823.47C638.64 822 648.8 819.99 658.74 817.46L628.31 743.98V743.99Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M704.45 361.2L760.65 305C752.39 298.81 743.78 293.06 734.87 287.76L704.45 361.2Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M545.89 743.99V823.47C559.35 825.39 573.11 826.4 587.1 826.4C601.09 826.4 614.85 825.39 628.31 823.47V743.99H545.89Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 329.66V250.18C614.85 248.26 601.09 247.25 587.1 247.25C573.11 247.25 559.35 248.26 545.89 250.18V329.66H628.31Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M628.31 329.66H545.89L469.75 361.2L411.47 419.47L379.93 495.62V578.03L411.47 654.17L469.75 712.45L545.89 743.99H628.31L704.45 712.45L762.72 654.17L794.26 578.03V495.62L762.72 419.47L704.45 361.2L628.31 329.66Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
        data-center
      />
      <path
        d="M469.75 712.45L439.33 785.89C462.78 799.83 488.35 810.56 515.46 817.46L545.89 743.98L469.75 712.44V712.45Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M704.45 361.2L734.87 287.76C711.42 273.82 685.85 263.09 658.74 256.19L628.31 329.67L704.45 361.21V361.2Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M379.93 495.62H300.45C298.53 509.08 297.52 522.84 297.52 536.83C297.52 550.82 298.53 564.58 300.45 578.04H379.93V495.62Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M794.26 495.62V578.04H873.74C875.66 564.58 876.67 550.82 876.67 536.83C876.67 522.84 875.66 509.08 873.74 495.62H794.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M794.26 578.03L762.72 654.17L836.16 684.59C850.1 661.14 860.83 635.57 867.73 608.46L794.25 578.03H794.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M379.93 495.62L411.47 419.48L338.03 389.06C324.09 412.51 313.36 438.08 306.46 465.19L379.94 495.62H379.93Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M411.47 654.17L355.27 710.37C371.83 732.45 391.46 752.09 413.55 768.65L469.75 712.45L411.47 654.17Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M762.72 419.47L818.92 363.27C802.36 341.19 782.73 321.55 760.64 304.99L704.44 361.19L762.72 419.47Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M469.75 361.2L413.55 305C391.47 321.56 371.83 341.19 355.27 363.28L411.47 419.48L469.75 361.2Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M704.45 712.45L760.65 768.65C782.73 752.09 802.37 732.46 818.93 710.37L762.73 654.17L704.45 712.45Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M794.26 495.62L867.74 465.19C860.84 438.08 850.11 412.51 836.17 389.06L762.73 419.48L794.27 495.62H794.26Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M411.47 654.17L379.93 578.03L306.45 608.46C313.35 635.57 324.08 661.14 338.02 684.59L411.46 654.17H411.47Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M704.45 712.45L628.31 743.99L658.74 817.47C685.85 810.57 711.42 799.84 734.87 785.9L704.45 712.46V712.45Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M469.75 361.2L545.89 329.66L515.46 256.18C488.35 263.08 462.78 273.81 439.33 287.75L469.75 361.19V361.2Z"
        fill="white"
        stroke="#C3C1C4"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </>
  )
}

HomepageHeroPaths.displayName = 'HomepageHeroPaths'

export default HomepageHeroPaths
