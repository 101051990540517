'use client'

import { useState, useRef, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import styles from './AccordionComponent.module.scss'
import Content from './AccordionContent/Content'
import Header from './AccordionHeader/Header'
import gsap from 'gsap'
import useBreakpoint from '@/hooks/use-breakpoint'
import HeaderStatic from '@/sections/Accordion/AccordionComponent/AccordionHeader/HeaderStatic'

const DURATION = 0.4

const AccordionComponent = ({ className, items, exclusiveOpen = false, initialOpenIndex }: AccordionProps) => {
  items = useMemo(() => {
    return items.filter(item => !item.isHidden)
  }, [items])
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({})
  const accordionRefs = useRef<Array<HTMLDivElement | null>>([])
  const [indexHovering, setIndexHovering] = useState(-1)
  const { isMobile } = useBreakpoint()

  const animateAccordion = (itemId: number, isOpen: boolean) => {
    gsap.killTweensOf(accordionRefs.current[itemId])
    gsap.fromTo(accordionRefs.current[itemId], isOpen ? {} : { height: 0 }, {
      height: isOpen ? 0 : 'auto',
      duration: isOpen ? DURATION : DURATION * 1.5,
      ease: 'Power3.easeOut',
    })
  }

  const toggleItem = (itemId: number) => {
    const openAccordion = openItems[itemId]
    animateAccordion(itemId, openAccordion)

    if (exclusiveOpen) {
      setOpenItems({ [itemId]: !openItems[itemId] })
      Object.keys(openItems).forEach(key => {
        if (Number(key) !== itemId) {
          animateAccordion(parseInt(key), true)
          setOpenItems(prevState => ({
            ...prevState,
            [key]: false,
          }))
        }
      })
    } else {
      setOpenItems(prevState => ({
        ...prevState,
        [itemId]: !prevState[itemId],
      }))
    }
  }

  const openInitialItem = (index: number) => {
    gsap.set(accordionRefs.current[index], {
      height: 'auto',
    })
    setOpenItems({ [index]: true })
  }

  useEffect(() => {
    if (initialOpenIndex !== undefined) {
      openInitialItem(initialOpenIndex)
    }
  }, [initialOpenIndex])

  return (
    <div className={classnames(styles.Accordion, className)}>
      {items.map((item, index) => {
        const accordionId = `accordion_id_${index}`
        const headerId = `header_id_${index}`
        const isOpen = openItems[index]

        return (
          <div
            key={index}
            className={classnames(
              styles.item,
              { [styles.isOpen]: isOpen },
              { [styles.isHovering]: indexHovering === index },
              { [styles.noDescription]: !item?.description },
            )}
            data-accordion-item
            onMouseEnter={() => {
              if (isMobile) return
              setIndexHovering(index)
            }}
            onMouseLeave={() => {
              if (isMobile) return
              setIndexHovering(-1)
            }}
          >
            {!item?.description ? (
              <HeaderStatic
                key={index}
                item={item}
              />
            ) : (
              <>
                <Header
                  item={item}
                  toggleItem={toggleItem}
                  accordionId={accordionId}
                  headerId={headerId}
                  index={index}
                  isOpen={isOpen}
                  isHovering={indexHovering === index}
                />
                <div
                  className={classnames(styles.contentWrapper, isOpen && styles.contentWrapperOpen)}
                  ref={el => {
                    accordionRefs.current[index] = el
                  }}
                >
                  <Content
                    item={item}
                    accordionId={accordionId}
                    headerId={headerId}
                  />
                </div>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

AccordionComponent.displayName = 'AccordionComponent'

export default AccordionComponent
