const RotatedArrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.646447"
        y1="11.3545"
        x2="10.6464"
        y2="1.35445"
        stroke="currentColor"
      />
      <path
        d="M1.80664 0.999994L10.999 0.999994L10.999 10.1924"
        stroke="currentColor"
      />
    </svg>
  )
}

RotatedArrow.displayName = 'RotatedArrow'

export default RotatedArrow
