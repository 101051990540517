'use client'

import classnames from 'classnames'
import styles from './AnimatedTextLines.module.scss'
import { ReactNode, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import useWindowResize from '@/hooks/use-window-resize'
import useInView from '@/hooks/use-in-view'
import useStore from '@/store'

gsap.registerPlugin(SplitText)

type AnimatedTextLinesProps = {
  className?: string
  children: ReactNode
  querySelector: string
  disableLineBreakCheck?: boolean
}

const AnimatedTextLines = ({ className, children, querySelector, disableLineBreakCheck }: AnimatedTextLinesProps) => {
  const $inner = useRef<HTMLDivElement | null>(null)
  const splitTextRef = useRef<SplitText | null>(null)
  const resizeKey = useWindowResize()
  const [rendered, setRendered] = useState(false)
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>()
  const hasAnimatedRef = useRef(false)
  const [hasFinishesAnimated, setHasFinishesAnimated] = useState(false)
  const { setElementToObserve, isInView } = useInView()
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const fontsLoaded = useStore(state => state.fontsLoaded)
  const hasAnimatedIn = useRef(false)

  const animate = () => {
    if (!splitTextRef.current || !$inner.current) return
    const linesNodeList = $inner.current.querySelectorAll(`.${styles.line}`)
    if (!linesNodeList) return
    const $lines = Array.from(linesNodeList)
    if (!$lines.length) return
    gsap.killTweensOf($lines)
    gsap.to($lines, {
      autoAlpha: 1,
      y: 0,
      duration: 0.8,
      stagger: 0.1,
      onComplete: () => {
        setHasFinishesAnimated(true)
      },
    })
  }

  useEffect(() => {
    if (!fontsLoaded) return
    if (!$inner.current) return
    const elementsNodeList = $inner.current?.querySelectorAll(querySelector)
    const elements = Array.from(elementsNodeList)
    if (!elements?.length) return

    if (!disableLineBreakCheck) {
      elements.forEach(el => {
        if (!el) return
        if (el.textContent?.includes('\n')) {
          const text = el.textContent.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          el.innerHTML = text
        }
      })
    }

    if (splitTextRef.current) splitTextRef.current.revert()

    splitTextRef.current = new SplitText(elements, { type: 'lines', linesClass: styles.line })

    setRendered(true)
  }, [querySelector, resizeKey, disableLineBreakCheck, fontsLoaded])

  useEffect(() => {
    if (!fontsLoaded) return
    if (pageIsTransitioning) return
    if (!isInView || hasAnimatedIn.current) return
    hasAnimatedIn.current = true

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    debounceTimeout.current = setTimeout(() => {
      if (hasAnimatedRef.current) return
      hasAnimatedRef.current = true
      animate()
    }, 75)
  }, [rendered, isInView, pageIsTransitioning, fontsLoaded])

  return (
    <div
      className={classnames(
        styles.AnimatedTextLines,
        className,
        { [styles.hasFinishesAnimated]: hasFinishesAnimated },
        { [styles.rendered]: rendered },
      )}
    >
      <div
        ref={ref => {
          $inner.current = ref
          setElementToObserve(ref)
        }}
        className={styles.inner}
      >
        {children}
      </div>
    </div>
  )
}

AnimatedTextLines.displayName = 'AnimatedTextLines'

export default AnimatedTextLines
