import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Link from '@/components/Link/Link'
import styles from './BlogContentRichText.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import { CSSProperties } from 'react'
import FadeIn from '@/components/FadeIn/FadeIn'
import classNames from 'classnames'
import Button from '@/components/Button/Button'
import ContactForm from '@/sections/ContactForm/ContactForm'
import VimeoPlayer from '@/components/VimeoPlayer/VimeoPlayer'

const serializer: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ value, text }) => {
      return (
        <Link
          link={{
            ...value,
            label: text,
          }}
          className={styles.richTextLink}
        />
      )
    },
  },
  block: {
    normal: ({ children }) => <p className={styles.p}>{children}</p>,
    h2: ({ children }) => <h2 className={styles.h2}>{children}</h2>,
    h3: ({ children }) => <h3 className={styles.h3}>{children}</h3>,
  },
  listItem: {
    bullet: ({ children }) => <li className={styles.li}>{children}</li>,
  },
  list: {
    bullet: ({ children }) => <ul className={styles.ul}>{children}</ul>,
  },
  types: {
    link: ({ value }) => {
      return (
        <div className={styles.linkWrapper}>
          <Button
            type="secondary"
            icon="rotated-arrow"
            link={value}
          />
        </div>
      )
    },
    blogPostFootnote: ({ value }) => {
      return (
        <div className={styles.footnote}>
          <PortableText
            // eslint-disable-next-line
            value={value?.blogPostFootnote.text as any}
            components={serializer}
          />
        </div>
      )
    },
    pdfContactForm: ({ value }) => {
      return (
        <div className={styles.pdfContactFormContainer}>
          <ContactForm
            _type="anystring"
            _id="anystring"
            smallText={value.pdfContactForm.smallText}
            fullWidth={true}
            pdfName={value.pdfContactForm.pdfName}
            reasonDropdownOptions={[
              'Academic',
              'Art Historian',
              'Critic',
              'Curator',
              'Dealer',
              'Journalist',
              'Scientist',
              'Just Curious',
            ]}
            reasonDropdownTitle="Reason for interest"
            // disableRecaptcha
            className={styles.pdfContactForm}
            isPdfRequest={true}
          />
        </div>
      )
    },
    blockquote: ({ value }) => {
      return <blockquote className={styles.blockquote}>{value?.blockquote?.text}</blockquote>
    },
    vimeoEmbed: ({ value }) => {
      return (
        <VimeoPlayer
          vimeoId={value?.vimeoEmbed?.vimeoId}
          mutedAndLooping={Boolean(value?.vimeoEmbed?.mutedAndLooping)}
          fullWidth={Boolean(value?.vimeoEmbed?.fullWidth)}
          aspectRatio={value?.vimeoEmbed?.aspectRatio}
          className={classNames(styles.vimeoEmbed, { [styles.isFullWidth]: Boolean(value?.vimeoEmbed?.fullWidth) })}
        />
      )
    },
    imageBlock: ({ value }) => {
      const image = value.imageBlock?.image

      if (!image) return null

      return (
        <>
          <FadeIn className={classNames(styles.imageBlockFadeIn, styles[value.imageBlock.imageStyle])}>
            <div
              className={styles.imageBlock}
              style={
                {
                  '--aspect-ratio': image.asset.metadata.dimensions.aspectRatio,
                } as CSSProperties
              }
            >
              <SanityImage
                className={styles.imageBlock__image}
                image={image}
                breakpoints={{
                  laptop: {
                    width: 1400,
                    image: image,
                  },
                  mobile: {
                    width: 800,
                    image: image,
                  },
                  xs: {
                    width: 500,
                    image: image,
                  },
                }}
              />
            </div>
          </FadeIn>
          {value.imageBlock.text && (
            <p
              className={classNames(
                styles.imageBlock__text,
                { [styles.serifCaption]: value.imageBlock.useSerifCaption },
                styles[value.imageBlock.imageStyle],
              )}
            >
              <PortableText
                // eslint-disable-next-line
                value={value.imageBlock.text as any}
                components={{
                  block: {
                    normal: ({ children }) => <span>{children}</span>,
                  },
                }}
              />
            </p>
          )}
        </>
      )
    },
  },
}

const BlogContentRichText = ({ content }: SanityContentBlockProps) => {
  return (
    <div className={styles.container}>
      <PortableText
        value={content}
        onMissingComponent={false}
        components={serializer}
      />
    </div>
  )
}

BlogContentRichText.displayName = 'BlogContentRichText'

export default BlogContentRichText
