import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import styles from './RichTextWithIconRichText.module.scss'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  marks: {
    bold: ({ text }) => {
      return <span className={styles.bold}>{text}</span>
    },
  },
  block: {
    normal: ({ children }) => (
      <AnimatedTextLines
        disableLineBreakCheck
        querySelector="h1"
      >
        <h1 className={styles.title}>{children}</h1>
      </AnimatedTextLines>
    ),
  },
}

const RichTextWithIconRichText = ({ content }: SanityContentBlockProps) => {
  return (
    <PortableText
      value={content}
      onMissingComponent={false}
      components={portableTextSerializer}
    />
  )
}

RichTextWithIconRichText.displayName = 'RichTextWithIconRichText'

export default RichTextWithIconRichText
