'use client'

import classnames from 'classnames'
import styles from './RichTextWithIcon.module.scss'
import RichTextWithIconRichText from '@/sections/RichTextWithIcon/RichTextWithIconRichText/RichTextWithIconRichText'
import { useEffect, useRef, useState } from 'react'
import lottie, { AnimationItem } from 'lottie-web'
import useInView from '@/hooks/use-in-view'
import { LOTTIE_SYMBOL_PATHS } from '@/data'
import FadeIn from '@/components/FadeIn/FadeIn'
import useStore from '@/store'

const RichTextWithIcon = ({ className, lottieIconAnimation = '1', eyebrow, title }: SanityRichTextWithIcon) => {
  const $lottieContainer = useRef<HTMLDivElement | null>(null)
  const lottieAnimationRef = useRef<AnimationItem>()
  const { setElementToObserve, isInView } = useInView()
  const [isLoaded, setIsLoaded] = useState(false)
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)

  useEffect(() => {
    if (!$lottieContainer.current) return
    const animationPath = LOTTIE_SYMBOL_PATHS[lottieIconAnimation]

    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy()
    }

    lottieAnimationRef.current = lottie.loadAnimation({
      container: $lottieContainer.current as HTMLDivElement,
      renderer: 'svg',
      loop: false,
      path: animationPath,
      autoplay: false,
    })
    lottieAnimationRef.current.addEventListener('DOMLoaded', function () {
      setIsLoaded(true)
    })

    return () => {
      if (lottieAnimationRef.current) {
        lottieAnimationRef.current.destroy()
      }
    }
  }, [lottieIconAnimation])

  useEffect(() => {
    if (isInView && lottieAnimationRef.current && isLoaded && !pageIsTransitioning) {
      lottieAnimationRef.current.play()
    }
  }, [isInView, isLoaded, pageIsTransitioning])

  if (!title || !lottieIconAnimation) return null

  return (
    <div className={classnames(styles.RichTextWithIcon, className)}>
      <div className={styles.inner}>
        <div
          className={styles.iconContainer}
          ref={ref => {
            setElementToObserve(ref)
            if (ref) $lottieContainer.current = ref
          }}
        />
        <div className={styles.textContainer}>
          {eyebrow && (
            <FadeIn className={styles.eyebrowContainer}>
              <p className={styles.eyebrow}>{eyebrow}</p>
            </FadeIn>
          )}
          <div className={styles.titleContainer}>
            <div className={styles.titleContainerInner}>
              <RichTextWithIconRichText content={title} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RichTextWithIcon.displayName = 'RichTextWithIcon'

export default RichTextWithIcon
