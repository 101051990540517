'use client'

import classnames from 'classnames'
import styles from './HomepageHero.module.scss'
import HomepageHeroShape from '@/sections/HomepageHero/HomepageHeroShape/HomepageHeroShape'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const HOME_HERO_CONTAINER_ID = 'homeHeroContainer'
const TITLE_ID = 'homeHeroTitle'

const HomepageHero = ({ className, title, image }: SanityHomepageHero) => {
  if (!title || !image) return null

  return (
    <div
      id={HOME_HERO_CONTAINER_ID}
      className={classnames(styles.HomepageHero, className)}
    >
      <div className={styles.inner}>
        <div className={styles.titleContainer}>
          <div className={styles.titleContainer__inner}>
            <h1 className={styles.title}>
              <span
                id={TITLE_ID}
                className={styles.title__inner}
              >
                <span className={styles.title__inner__large}>{title}</span>
              </span>
            </h1>
            <div className={styles.shapeContainer}>
              <HomepageHeroShape
                image={image}
                containerId={HOME_HERO_CONTAINER_ID}
                titleId={TITLE_ID}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomepageHero.displayName = 'HomepageHero'

export default HomepageHero
