'use client'

import { useContext, useEffect, useRef } from 'react'
import styles from './Footer.module.scss'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import Link from '@/components/Link/Link'
import lottie, { AnimationItem } from 'lottie-web'
import useInView from '@/hooks/use-in-view'
import FadeIn from '@/components/FadeIn/FadeIn'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import useBreakpoint from '@/hooks/use-breakpoint'

const Footer = () => {
  const { footerData } = useContext(GlobalSettingsContext)
  const lottieAnimationRef = useRef<AnimationItem>()
  const logoContainerRef = useRef<HTMLDivElement>()
  const { setElementToObserve, isInView: lottieInView } = useInView()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    if (!logoContainerRef.current && !lottieAnimationRef.current) return
    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy()
    }
    lottieAnimationRef.current = lottie.loadAnimation({
      container: logoContainerRef.current as HTMLDivElement,
      renderer: 'svg',
      loop: false,
      path: '/lottie/footer-icon.json',
      autoplay: false,
    })
    return () => {
      if (lottieAnimationRef.current) {
        lottieAnimationRef.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (lottieInView && lottieAnimationRef.current) {
      lottieAnimationRef.current.play()
    }
  }, [lottieInView])

  if (!footerData?.linkColumns?.length || !footerData?.legal || !footerData?.bottomTitle || !footerData?.address) {
    return null
  }

  return (
    <footer className={styles.Footer}>
      <div className={styles.inner}>
        <div className={styles.top}>
          {footerData?.linkColumns.map((linkColumn, i) => {
            return (
              <div
                className={styles.linkColumn}
                key={i}
              >
                <FadeIn>
                  <p className={styles.linkColumn__title}>{linkColumn.title}</p>
                </FadeIn>
                <ul className={styles.linkColumn__list}>
                  {linkColumn.items.map((item, _i) => {
                    return (
                      <li
                        className={styles.linkColumn__listItem}
                        key={_i}
                      >
                        <FadeIn delay={i * 0.05}>
                          <Link
                            className={styles.linkColumn__listItemLink}
                            link={item}
                          />
                        </FadeIn>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
          <div className={styles.addressColumn}>
            <FadeIn>
              <p className={styles.addressColumn__title}>Address</p>
            </FadeIn>
            <FadeIn>
              <p className={styles.addressColumn__address}>{footerData?.address}</p>
            </FadeIn>
          </div>
          <div className={styles.legalColumn}>
            <FadeIn>
              <p className={styles.legalColumn__title}>(C)</p>
            </FadeIn>
            <FadeIn>
              <p className={styles.legalColumn__legal}>{footerData?.legal}</p>
            </FadeIn>
          </div>
        </div>
        <div className={styles.bottom}>
          {isMobile ? (
            <FadeIn>
              <p className={styles.bottomTitle}>{footerData?.bottomTitle.replace(/\n/g, ' ').replace(/\s+/g, ' ')}</p>
            </FadeIn>
          ) : (
            <AnimatedTextLines querySelector="p">
              <p className={styles.bottomTitle}>{footerData?.bottomTitle}</p>
            </AnimatedTextLines>
          )}
          <div
            className={styles.lottieContainer}
            ref={ref => {
              if (!ref) return
              logoContainerRef.current = ref
              setElementToObserve(ref)
            }}
          />
        </div>
      </div>
    </footer>
  )
}

Footer.displayName = 'Footer'

export default Footer
