'use client'

import classnames from 'classnames'
import styles from './TeamMembers.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import FadeIn from '@/components/FadeIn/FadeIn'

const TeamMembers = ({ className, title, teamMembers }: SanityTeamMembers) => {
  if (!title || !teamMembers) return null

  return (
    <div className={classnames(styles.TeamMembers, className)}>
      <div className={styles.inner}>
        <div className={styles.titleColumn}>
          <AnimatedTextLines
            querySelector="h1"
            className={styles.titleContainer}
          >
            <h1 className={styles.title}>{title}</h1>
          </AnimatedTextLines>
        </div>
        <ul className={styles.membersColumn}>
          {teamMembers.map((person, i) => (
            <li
              key={i}
              className={styles.person}
            >
              <article className={styles.person__inner}>
                <FadeIn className={styles.person__imageContainer}>
                  <SanityImage
                    image={person.image}
                    className={styles.person__image}
                    breakpoints={{
                      xs: {
                        width: 500,
                        image: person.image,
                      },
                    }}
                  />
                </FadeIn>
                <div className={styles.person__textContent}>
                  <FadeIn
                    className={styles.person__nameContainer}
                    type="fadeInUp"
                  >
                    <h1 className={styles.person__name}>{person.fullName}</h1>
                  </FadeIn>
                  {person.designation && (
                    <FadeIn className={styles.person__designationContainer}>
                      <p className={styles.person__designation}>{person.designation}</p>
                    </FadeIn>
                  )}
                  {person.bio && (
                    <FadeIn className={styles.person__bioContainer}>
                      <p className={styles.person__bio}>{person.bio}</p>
                    </FadeIn>
                  )}
                </div>
              </article>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

TeamMembers.displayName = 'TeamMembers'

export default TeamMembers
