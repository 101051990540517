'use client'

import classnames from 'classnames'
import styles from './PaginatedBlogPostsCard.module.scss'
import Link from '@/components/Link/Link'
import { DOC_TYPES } from '@/data'
// import { getFormattedDateString } from '@/utils'
import Button from '@/components/Button/Button'
import { useMemo, useRef } from 'react'
import SanityImage from '@/components/SanityImage/SanityImage'
import { ALL_TEXT } from '@/sections/PaginatedBlogPosts/PaginatedBlogPosts'
import useBreakpoint from '@/hooks/use-breakpoint'
import MaskReveal from '@/components/MaskReveal/MaskReveal'
import BlogTitleRenderer from '@/components/BlogTitleRenderer/BlogTitleRenderer'
import { PortableText } from 'next-sanity'

type PaginatedBlogPostsCardProps = SanityPage & {
  className?: string
  index: number
  activeFilter: string
}

const PaginatedBlogPostsCard = (props: PaginatedBlogPostsCardProps) => {
  const { className, index, slug, activeFilter, blogData } = props
  const { isMobile } = useBreakpoint()
  const isBig = useMemo(() => {
    return !isMobile && activeFilter === ALL_TEXT && index === 0
  }, [activeFilter, index, isMobile])
  const $button = useRef<ButtonImperativeHandle | null>(null)

  return (
    <article className={classnames(styles.PaginatedBlogPostsCard, className, { [styles.isBig]: isBig })}>
      <Link
        link={{
          linkType: blogData?.externalLinkOverride ? 'external' : 'internal',
          link: blogData?.externalLinkOverride
            ? blogData?.externalLinkOverride
            : {
                slug: slug.current,
                _type: DOC_TYPES.BLOG_POST,
              },
        }}
        className={styles.link}
        onMouseEnter={() => {
          $button.current?.setIsHover(true)
        }}
        onMouseLeave={() => {
          $button.current?.setIsHover(false)
        }}
      >
        <MaskReveal className={styles.imageContainer}>
          <SanityImage
            className={styles.imageContainer__image}
            image={blogData?.image as SanityImage}
            breakpoints={{
              mobile: {
                image: blogData?.image as SanityImage,
                width: 1200,
              },
              xs: {
                image: blogData?.image as SanityImage,
                width: 800,
              },
            }}
          />
        </MaskReveal>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <BlogTitleRenderer {...props} />
          </h1>
          {/* <div className={styles.filterDateContainer}>
            <span className={styles.filterDateContainer__category}>{blogData?.blogCategory}</span>
            <span>&nbsp;•&nbsp;</span>
            <span className={styles.filterDateContainer__date}>
              {getFormattedDateString(blogData?.publishedDate || _createdAt)}
            </span>
          </div> */}
          {!!blogData?.subtitle?.length && (
            <div className={styles.subtitle}>
              {' '}
              <PortableText
                // eslint-disable-next-line
                value={blogData?.subtitle as any}
              />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <Button
              type={isBig ? 'primary' : 'small'}
              label="Read More"
              ref={$button}
              className={styles.button}
              disableHoverAnimation
            />
          </div>
        </div>
      </Link>
    </article>
  )
}

PaginatedBlogPostsCard.displayName = 'PaginatedBlogPostsCard'

export default PaginatedBlogPostsCard
