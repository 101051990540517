'use client'

import classnames from 'classnames'
import styles from './TitleAndRichTextScroller.module.scss'
import RichTextBasic, { richTextBasicSerializer } from '@/components/RichTextBasic/RichTextBasic'
import SanityImage from '@/components/SanityImage/SanityImage'
import { CSSProperties } from 'react'
import MaskReveal from '@/components/MaskReveal/MaskReveal'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import FadeIn from '@/components/FadeIn/FadeIn'

const SERIALIZER = richTextBasicSerializer
SERIALIZER.types = {
  ...richTextBasicSerializer.types,
  imageAsset: ({ value }) => {
    return (
      <div
        className={styles.richTextImageContainer}
        style={
          {
            '--aspect-ratio': value?.asset?.metadata?.dimensions?.aspectRatio,
          } as CSSProperties
        }
      >
        <SanityImage
          image={value}
          className={styles.richTextImage}
          breakpoints={{
            tablet: {
              image: value,
              width: 640,
            },
            xs: {
              image: value,
              width: 500,
            },
          }}
        />
      </div>
    )
  },
}

const TitleAndRichTextScroller = ({
  className,
  eyebrow,
  title,
  content,
  bottomImage,
}: SanityTitleAndRichTextScroller) => {
  if (!title || !content?.length) return null

  return (
    <div className={classnames(styles.TitleAndRichTextScroller, className)}>
      <div className={styles.inner}>
        <div className={styles.topContent}>
          <div className={styles.titleColumn}>
            <div className={styles.titleContainer}>
              {eyebrow && (
                <FadeIn>
                  <p className={styles.eyebrow}>{eyebrow}</p>
                </FadeIn>
              )}
              <AnimatedTextLines querySelector="h1">
                <h1 className={styles.title}>{title}</h1>
              </AnimatedTextLines>
            </div>
          </div>
          <div className={styles.contentColumn}>
            <div className={styles.content}>
              <RichTextBasic
                content={content}
                serializer={SERIALIZER}
              />
            </div>
          </div>
        </div>
        {bottomImage && (
          <div
            style={
              {
                '--aspect-ratio': bottomImage?.asset?.metadata?.dimensions?.aspectRatio,
              } as CSSProperties
            }
          >
            <MaskReveal className={styles.bottomContent}>
              <SanityImage
                className={styles.bottomImage}
                image={bottomImage}
                breakpoints={{
                  tablet: {
                    width: 1300,
                    image: bottomImage,
                  },
                  xs: {
                    width: 600,
                    image: bottomImage,
                  },
                }}
              />
            </MaskReveal>
          </div>
        )}
      </div>
    </div>
  )
}

TitleAndRichTextScroller.displayName = 'TitleAndRichTextScroller'

export default TitleAndRichTextScroller
