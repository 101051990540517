'use client'

import classnames from 'classnames'
import styles from './HomepageHeroShape.module.scss'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import SanityImage from '@/components/SanityImage/SanityImage'
import gsap from 'gsap'
import HomepageHeroPaths from '@/sections/HomepageHero/HomepageHeroPaths/HomepageHeroPaths'
import HomepageHeroPathsMobile from '@/sections/HomepageHero/HomepageHeroPaths/HomepageHeroPathsMobile'
import { deviceInfo, shuffleArray } from '@/utils'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import useBreakpoint from '@/hooks/use-breakpoint'
import Image from 'next/image'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const MASK_ID = 'heroMask'

const SVG_WIDTH = 1174
const SVG_HEIGHT = 1061

const HomepageHeroShape = ({
  className,
  image,
  containerId,
  titleId,
}: {
  className?: string
  image: SanityImage
  containerId: string
  titleId: string
}) => {
  const resizeKey = useWindowResize()
  const $svg = useRef<SVGSVGElement | null>(null)
  const $mask = useRef<SVGMaskElement | null>(null)
  const $svgImage = useRef<SVGImageElement | null>(null)
  const $container = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<GSAPTimeline | null>(null)
  const timelineTitleRef = useRef<GSAPTimeline | null>(null)
  const { breakpoint, isMobile } = useBreakpoint()
  const [isSmallPathShape, setIsSmallPathShape] = useState<boolean | null>(null)
  const startedAnimation = useRef(false)

  let imageToUse = null
  if (breakpoint?.name === 'mobile') {
    imageToUse = '/path-images/path_bg-small.webp'
  } else if (breakpoint?.name === 'tablet' || breakpoint?.name === 'laptop') {
    imageToUse = '/path-images/path_bg.webp'
  } else {
    imageToUse = '/path-images/path_bg-xl.webp'
  }

  useEffect(() => {
    setIsSmallPathShape(deviceInfo.browser.safari)
  }, [])

  useEffect(() => {
    if (!$svg.current || !$svgImage.current) return
    const windowRatio = window.innerWidth / window.innerHeight
    const viewbox = $svg.current.getAttribute('viewBox')
    const values = (viewbox as string).split(' ')
    const width = parseInt(values[2])
    const height = parseInt(values[3])
    const svgRatio = width / height

    if (windowRatio > svgRatio) {
      gsap.set($svg.current, {
        height: 'auto',
        width: '100%',
      })
    } else {
      gsap.set($svg.current, {
        height: '100%',
        width: 'auto',
      })
    }

    gsap.to($container.current, {
      autoAlpha: 1,
    })
  }, [resizeKey, breakpoint])

  useEffect(() => {
    if (isSmallPathShape === null || startedAnimation.current) return

    startedAnimation.current = true

    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    if (timelineTitleRef.current) {
      timelineTitleRef.current.kill()
    }

    const nodeList = $mask.current?.querySelectorAll('path')

    if (!nodeList) return

    let centerPath = null
    const maskPaths = Array.from(nodeList).filter(el => {
      const isCenter = el.hasAttribute('data-center')
      if (isCenter) centerPath = el
      return !isCenter
    })

    const resetMasksToBeginning = () => {
      gsap.set(maskPaths, {
        fill: '#ffffff',
        stroke: '#ffffff',
      })
    }

    resetMasksToBeginning()

    const baseDelay = 0.75

    timelineRef.current = gsap.timeline({
      delay: baseDelay,
    })

    const randomized = shuffleArray(maskPaths as [])
    const duration = isSmallPathShape ? 0.03 : 0.008

    randomized.forEach((el, i) => {
      if (!el || !timelineRef.current) return

      if (i > maskPaths.length / 2 || isMobile) {
        const toAnimate = [el]
        const sibling1 = randomized[i - 2]
        const sibling2 = randomized[i - 1]
        const sibling3 = randomized[i + 1]
        const sibling4 = randomized[i + 2]
        if (sibling1) toAnimate.push(sibling1)
        if (sibling2) toAnimate.push(sibling2)
        if (sibling3) toAnimate.push(sibling3)
        if (sibling4) toAnimate.push(sibling4)

        timelineRef.current.to(toAnimate, {
          fill: '#000000',
          stroke: '#000000',
          duration,
        })
      } else {
        timelineRef.current.to(el, {
          fill: '#000000',
          stroke: '#000000',
          duration,
        })
      }
    })

    const mainContainer = document.getElementById(containerId)
    const title2 = document.getElementById(titleId)

    if (!mainContainer) return

    timelineTitleRef.current = gsap.timeline({
      delay: baseDelay,
    })
    const titleDuration = 0.7

    timelineTitleRef.current.fromTo(
      centerPath,
      {
        fill: '#ffffff',
        stroke: '#ffffff',
      },
      {
        fill: '#000000',
        stroke: '#000000',
        duration: titleDuration,
        delay: titleDuration,
      },
    )

    timelineTitleRef.current.fromTo(
      title2,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: titleDuration,
      },
    )
  }, [resizeKey, containerId, titleId, isMobile, isSmallPathShape])

  useEffect(() => {
    return () => {
      if (timelineRef.current) timelineRef.current.kill()
      if (timelineTitleRef.current) timelineTitleRef.current.kill()
    }
  }, [])

  return (
    <>
      <Image
        src={imageToUse}
        priority
        alt=""
        width={2348}
        height={2098}
        className={styles.imageToLoad}
      />
      <div
        ref={$container}
        className={classnames(styles.HomepageHeroShape, className)}
      >
        <SanityImage
          image={image}
          className={styles.image}
          breakpoints={{
            desktop: {
              width: 1440,
              image,
            },
            mobile: {
              width: 900,
              image,
            },
            xs: {
              width: 400,
              image,
            },
          }}
        />
        <svg
          ref={$svg}
          className={styles.svg}
          viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice"
        >
          <mask
            ref={$mask}
            className={styles.svgMask}
            id={MASK_ID}
          >
            {isSmallPathShape && <HomepageHeroPathsMobile />}
            {isSmallPathShape === false && <HomepageHeroPaths />}
          </mask>
          <g
            className={styles.svgGroupFilled}
            clipPath="url(#clip0_405_3577)"
            mask={`url(#${MASK_ID})`}
          >
            {imageToUse && (
              <image
                href={imageToUse}
                height="100%"
                width="100%"
                ref={$svgImage}
                className={styles.svgPathImage}
              />
            )}
            {/* <HomepageHeroPaths /> */}
          </g>
          <defs>
            <clipPath id="clip0_405_3577">
              <rect
                width="1174"
                height="1061"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  )
}

HomepageHeroShape.displayName = 'HomepageHeroShape'

export default HomepageHeroShape
