'use client'

import classnames from 'classnames'
import styles from './Accordion.module.scss'
import AccordionComponent from '@/sections/Accordion/AccordionComponent/AccordionComponent'

const Accordion = ({ className, title, items }: SanityAccordion) => {
  if (!title || !items?.length) return null

  return (
    <div className={classnames(styles.Accordion, className)}>
      <div className={styles.inner}>
        <h1 className={styles.title}>{title}</h1>
        <AccordionComponent items={items} />
      </div>
    </div>
  )
}

Accordion.displayName = 'Accordion'

export default Accordion
