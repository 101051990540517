'use client'

import classnames from 'classnames'
import styles from './TitleAndImageHero.module.scss'
import RichTextBasic from '@/components/RichTextBasic/RichTextBasic'
import SanityImage from '@/components/SanityImage/SanityImage'
import MaskReveal from '@/components/MaskReveal/MaskReveal'
import FadeIn from '@/components/FadeIn/FadeIn'

const TitleAndImageHero = ({ className, title, description, image }: SanityTitleAndImageHero) => {
  if (!image || !title) return null

  return (
    <div className={classnames(styles.TitleAndImageHero, className)}>
      <div className={styles.inner}>
        <FadeIn className={styles.titleContainer}>
          <h1 className={styles.title}>{title}</h1>
        </FadeIn>
        {description && (
          <div className={styles.richTextContainer}>
            <FadeIn>
              <RichTextBasic content={description || []} />
            </FadeIn>
          </div>
        )}
        <MaskReveal className={styles.imageContainer}>
          <SanityImage
            priority
            className={styles.image}
            image={image}
            breakpoints={{
              tablet: {
                image: image,
                width: 1300,
              },
              mobile: {
                image: image,
                width: 900,
              },
              xs: {
                image: image,
                width: 500,
                options: {
                  height: 500,
                  fit: 'crop',
                },
              },
            }}
          />
        </MaskReveal>
      </div>
    </div>
  )
}

TitleAndImageHero.displayName = 'TitleAndImageHero'

export default TitleAndImageHero
