'use client'

import classnames from 'classnames'
import styles from './FourOhFour.module.scss'
import Link from '@/components/Link/Link'
import { DOC_TYPES, HOME_SLUG } from '@/data'

const FourOhFour = ({ className }: SanityFourOhFour) => {
  return (
    <div className={classnames(styles.FourOhFour, className)}>
      <div className={styles.inner}>
        <h1 className={styles.title}>404</h1>
        <p>
          <Link
            link={{
              label: 'Return home',
              link: {
                slug: HOME_SLUG,
                _type: DOC_TYPES.PAGE,
              },
              linkType: 'internal',
            }}
            className={styles.link}
          />
        </p>
      </div>
    </div>
  )
}

FourOhFour.displayName = 'FourOhFour'

export default FourOhFour
