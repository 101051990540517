const HomepageHeroPathsMobile = () => {
  return (
    <>
      <path
        d="M1169.65 12.7201L1313.76 -131.41L1255.49 -189.69L1111.37 -45.5899C1131.8 -27.1818 1151.25 -7.7186 1169.65 12.7201Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M62.3498 -45.5901L-63.9602 -171.87L-122.24 -113.59L4.06976 12.7199C22.4674 -7.71878 41.9206 -27.182 62.3498 -45.5901Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M-31.0203 496.03C-27.5329 442.486 -17.0711 389.626 0.0997925 338.79L-76.2402 307.19L-152.53 275.59C-177.617 346.583 -192.306 420.832 -196.14 496.03C-196.874 509.67 -197.24 523.403 -197.24 537.23C-197.24 551.057 -196.874 564.783 -196.14 578.41C-192.293 653.61 -177.591 727.858 -152.49 798.85L-76.2003 767.25C-97.3162 706.33 -109.899 642.78 -113.59 578.41C-114.377 564.77 -114.78 551.033 -114.8 537.2C-114.82 523.366 -114.417 509.633 -113.59 496L-31.0203 496.03Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M62.4302 1003.41L120.82 945.01C85.465 904.659 55.4979 859.888 31.6702 811.82L-44.6398 843.41C-56.6627 818.678 -67.2143 793.257 -76.2398 767.28L-152.53 798.88C-108.739 922.129 -34.8969 1032.51 62.3102 1120.02L120.76 1061.66C100.196 1043.4 80.7196 1023.95 62.4302 1003.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1369.86 496.03H1287.31C1288.1 509.663 1288.5 523.396 1288.51 537.23C1288.52 551.063 1288.12 564.8 1287.31 578.44C1283.62 642.81 1271.04 706.36 1249.92 767.28L1173.62 735.67C1147.55 812.709 1106.52 883.842 1052.89 944.99L1111.29 1003.39L1169.65 1061.74C1220.05 1005.81 1262.15 942.918 1294.65 875C1339.08 782.079 1364.64 681.272 1369.86 578.41C1370.57 564.763 1370.93 551.026 1370.93 537.2C1370.93 523.373 1370.57 509.65 1369.86 496.03Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M280.68 1168.73L312.29 1092.41C239.44 1056.26 174.389 1006.17 120.82 944.98L62.4299 1003.41C80.7192 1023.95 100.196 1043.4 120.76 1061.66L62.3999 1120.02C138.869 1188.99 228.195 1242.19 325.26 1276.58L356.86 1200.29C330.867 1191.28 305.43 1180.74 280.68 1168.73Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1326.2 275.59C1317.03 249.672 1306.49 224.26 1294.63 199.46C1262.13 131.544 1220.03 68.6534 1169.63 12.7202L1111.27 71.0802C1154.13 119.249 1190.15 173.087 1218.33 231.08L1142.03 262.68C1178.16 335.551 1199.48 414.864 1204.76 496.03H1369.86C1366.01 420.829 1351.31 346.58 1326.2 275.59Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M586.86 -164.42C573.02 -164.42 559.283 -164.02 545.65 -163.22V-245.77C470.45 -241.918 396.201 -227.213 325.21 -202.11L356.81 -125.83L388.42 -49.53L420.05 26.84C460.768 13.5435 502.938 5.19352 545.65 1.96997V-80.65C559.27 -81.55 573.007 -82.0034 586.86 -82.01C600.713 -82.0167 614.45 -81.5634 628.07 -80.65V-163.22C614.43 -164.007 600.693 -164.407 586.86 -164.42Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M816.9 1200.29L785.3 1123.99L753.67 1047.63C712.951 1060.92 670.782 1069.27 628.07 1072.5V1237.68C614.43 1238.47 600.693 1238.87 586.86 1238.89C573.027 1238.91 559.29 1238.51 545.65 1237.68C481.28 1233.99 417.73 1221.41 356.81 1200.29L325.21 1276.58C494.544 1336.21 679.166 1336.21 848.5 1276.58L816.9 1200.29Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M628.07 -245.77C614.423 -246.437 600.687 -246.794 586.86 -246.84C573.033 -246.887 559.297 -246.53 545.65 -245.77V-163.22C559.29 -164.014 573.027 -164.414 586.86 -164.42C600.693 -164.427 614.43 -164.027 628.07 -163.22V-80.6502C614.443 -81.5502 600.707 -82.0035 586.86 -82.0102C573.013 -82.0169 559.277 -81.5635 545.65 -80.6502V1.9698C559.25 0.929803 572.987 0.409805 586.86 0.409805C600.733 0.409805 614.47 0.929803 628.07 1.9698C670.782 5.19432 712.952 13.5443 753.67 26.8398L785.3 -49.5302L816.9 -125.83L848.5 -202.11C777.512 -227.213 703.267 -241.919 628.07 -245.77Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M586.86 1156.47C573.014 1156.47 559.277 1156.02 545.65 1155.12C492.11 1151.62 439.254 1141.16 388.42 1123.99L420.05 1047.63C393.897 1039.09 368.444 1028.53 343.92 1016.06L312.29 1092.41L280.68 1168.72C363.588 1209 453.656 1232.45 545.68 1237.72C559.32 1238.51 573.057 1238.91 586.89 1238.93C600.724 1238.95 614.46 1238.55 628.1 1237.72V1155.16C614.453 1155.99 600.707 1156.43 586.86 1156.47Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M924.63 -170.54C899.83 -182.404 874.418 -192.942 848.5 -202.11L816.9 -125.83L785.3 -49.5299L753.67 26.8401C779.808 35.3901 805.247 45.9448 829.76 58.4102L846.25 18.6001L861.39 -17.9498C909.453 5.88986 954.222 35.8596 994.58 71.2101L1052.97 12.8101L1111.37 -45.5899C1055.43 -95.9746 992.543 -138.055 924.63 -170.54Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M120.82 129.47L62.4297 71.0802L4.06976 12.7202C-64.9036 89.1896 -118.107 178.52 -152.49 275.59L-76.2402 307.19L0.0597534 338.79L76.4598 370.41C85.0036 344.257 95.5585 318.805 108.03 294.28L31.6697 262.65C55.5031 214.589 85.4698 169.822 120.82 129.47Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1111.37 -45.5898L1053.01 12.7701L994.62 71.1702C954.262 35.8196 909.493 5.84987 861.43 -17.9898L829.76 58.4102C892.588 90.3971 948.533 134.394 994.43 187.91L1052.85 129.48L1111.25 71.0901L1169.61 12.7302C1151.23 -7.71088 1131.79 -27.1774 1111.37 -45.5898Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M76.4599 704.04C63.1659 663.311 54.816 621.132 51.5899 578.41C50.5566 564.81 50.0366 551.073 50.0299 537.2C50.0233 523.327 50.5433 509.593 51.5899 496H-113.59C-114.377 509.633 -114.78 523.367 -114.8 537.2C-114.82 551.033 -114.417 564.77 -113.59 578.41C-108.323 670.434 -84.8714 760.503 -44.59 843.41L31.7199 811.8L108.08 780.17C95.5898 755.648 85.0182 730.195 76.4599 704.04Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M388.42 -49.5299L356.81 -125.83L325.21 -202.11C299.296 -192.94 273.887 -182.402 249.09 -170.54C156.223 -126.065 72.9847 -63.8024 4.08984 12.7201L62.4498 71.0801C80.7347 50.5343 100.205 31.0744 120.76 12.8002L179.15 71.2001C219.504 35.8451 264.274 5.87497 312.34 -17.9599L343.92 58.4102C368.446 45.9447 393.898 35.3933 420.05 26.8501L388.42 -49.5299Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M994.62 1003.27L936.19 944.84L877.76 886.41C853.182 906.913 826.511 924.769 798.19 939.68L829.85 1016.1C805.325 1028.57 779.873 1039.13 753.72 1047.67L785.35 1124.03L816.95 1200.33C904.01 1170.11 984.248 1123 1053.06 1061.7L994.62 1003.27Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1287.31 496.03H1204.76C1205.66 509.65 1206.11 523.383 1206.12 537.23C1206.13 551.076 1205.67 564.803 1204.76 578.41H1122.14C1118.91 621.121 1110.57 663.29 1097.28 704.01L1020.83 672.41C1012.65 698.696 1002.08 724.179 989.26 748.54L1065.69 780.19L1142.05 811.82C1154.26 787.177 1164.81 761.744 1173.62 735.69L1249.92 767.3C1271.04 706.364 1283.62 642.797 1287.31 578.41C1288.1 564.77 1288.5 551.033 1288.51 537.2C1288.52 523.366 1288.12 509.643 1287.31 496.03Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M237.76 828.09C217.255 803.514 199.399 776.843 184.49 748.52L108.06 780.17L31.7002 811.8C67.8506 884.65 117.94 949.701 179.13 1003.27L237.55 944.84L296 886.41C274.868 868.766 255.375 849.247 237.76 828.09Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1218.35 231.06C1190.17 173.067 1154.15 119.229 1111.29 71.0601L1052.89 129.45L994.47 187.88C976.556 166.997 957.073 147.513 936.19 129.6L877.76 188.1C923.577 226.322 961.458 273.155 989.26 325.95L1065.69 294.29C1078.16 318.813 1088.71 344.262 1097.26 370.41L1173.62 338.78C1164.81 312.727 1154.26 287.293 1142.05 262.65L1218.35 231.06Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1111.29 1003.41L1052.89 945.01C1088.24 904.656 1118.21 859.886 1142.05 811.82L1065.69 780.19C1046.27 818.364 1022.36 854.083 994.47 886.59L935.99 828.11C918.374 849.257 898.885 868.769 877.76 886.41L936.24 944.88L994.67 1003.31L1053.06 1061.7L1111.42 1120.06C1131.85 1101.66 1151.3 1082.21 1169.7 1061.78L1111.29 1003.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M586.86 1074.06C572.993 1074.06 559.257 1073.54 545.65 1072.5V906.96C524.59 904.642 503.767 900.524 483.41 894.65L451.7 971.2L420.05 1047.63L388.42 1123.99C439.253 1141.16 492.109 1151.62 545.65 1155.12C559.27 1156.01 573.007 1156.46 586.86 1156.47C600.713 1156.48 614.45 1156.03 628.07 1155.12V1072.5C614.47 1073.53 600.733 1074.05 586.86 1074.06Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M407.28 863.08C388.739 852.839 371.105 841.037 354.57 827.8L296 886.41L237.52 944.88L179.1 1003.31C219.455 1038.65 264.225 1068.6 312.29 1092.41L343.92 1016.05C368.444 1028.52 393.896 1039.08 420.05 1047.62L451.7 971.19L483.41 894.64C456.938 886.987 431.403 876.401 407.28 863.08Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1204.76 496.03H956.59C959.603 523.417 959.603 551.052 956.59 578.44H1039.42C1036.57 610.343 1030.34 641.853 1020.83 672.44L1097.26 704.09C1110.55 663.344 1118.9 621.148 1122.12 578.41H1204.76C1205.66 564.79 1206.11 551.053 1206.12 537.2C1206.13 523.346 1205.67 509.623 1204.76 496.03Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M296 188.1L237.52 129.62C216.64 147.534 197.16 167.017 179.25 187.9L120.82 129.47C85.4692 169.825 55.5025 214.596 31.6699 262.66L108.03 294.29C95.5591 318.811 85.0043 344.261 76.46 370.41L152.89 402.07C170.659 345.103 199.5 292.205 237.76 246.41L296.32 304.97C313.543 283.461 333.088 263.919 354.6 246.7L296 188.1Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M451.7 103.26C425.414 111.441 399.932 122.008 375.57 134.83L343.92 58.4098L312.29 -17.9502C264.224 5.88463 219.454 35.8547 179.1 71.2098L120.76 12.7998C100.215 31.075 80.7551 50.5348 62.48 71.0798L120.87 129.47L179.3 187.9C197.21 167.017 216.69 147.533 237.57 129.62L296.05 188.1L354.62 246.67C392.744 216.117 436.529 193.398 483.46 179.82L451.7 103.26Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M337.76 685L260.98 716.81C247.655 692.688 237.066 667.153 229.41 640.68L152.89 672.41L76.46 704.06C85.002 730.214 95.5569 755.667 108.03 780.19L184.46 748.54C199.38 776.857 217.245 803.522 237.76 828.09L296.32 769.52L355.06 710.78C348.839 702.5 343.064 693.895 337.76 685Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M300.21 578.41H134.29C133.07 564.843 132.456 551.107 132.45 537.2C132.443 523.293 133.057 509.56 134.29 496H51.5898C50.5565 509.6 50.0365 523.333 50.0298 537.2C50.0232 551.067 50.5432 564.803 51.5898 578.41C54.8183 621.122 63.1682 663.291 76.4598 704.01L152.89 672.41L229.44 640.7L306.22 608.89C303.669 598.846 301.663 588.671 300.21 578.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M722.01 971.2C691.443 980.707 659.953 986.942 628.07 989.8V906.96C649.127 904.643 669.946 900.524 690.3 894.65L658.5 817.87C648.472 820.415 638.314 822.418 628.07 823.87C600.735 827.777 572.984 827.777 545.65 823.87V1072.49C559.25 1073.52 572.987 1074.04 586.86 1074.05C600.733 1074.06 614.47 1073.54 628.07 1072.49C670.782 1069.26 712.95 1060.91 753.67 1047.62L722.01 971.2Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1173.62 338.79L1097.26 370.41C1088.71 344.258 1078.16 318.806 1065.69 294.28L989.26 325.94C1002.08 350.3 1012.65 375.783 1020.83 402.07L944.27 433.78L867.5 465.58C876.844 502.445 878.883 540.782 873.5 578.43H956.58C959.593 551.042 959.593 523.407 956.58 496.02H1204.76C1201.27 442.478 1190.8 389.622 1173.62 338.79Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M306.22 465.59L229.44 433.79C237.092 407.316 247.682 381.781 261.01 357.66L184.46 325.95C171.635 350.31 161.068 375.793 152.89 402.08L76.46 370.41L0.100037 338.78C-17.0709 389.616 -27.5326 442.476 -31.02 496.02H134.29C133.07 509.594 132.457 523.327 132.45 537.22C132.443 551.114 133.057 564.844 134.29 578.41H300.21C296.303 551.079 296.303 523.331 300.21 496C301.667 485.763 303.673 475.612 306.22 465.59Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M798.14 939.64L766.43 863.08L734.63 786.3C710.899 800.42 685.26 811.052 658.5 817.87L690.3 894.65C669.947 900.524 649.127 904.642 628.07 906.96V989.8C659.954 986.942 691.443 980.707 722.01 971.2L753.67 1047.63C779.823 1039.09 805.276 1028.53 829.8 1016.06L798.14 939.64Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M829.76 58.4101C805.234 45.9462 779.781 35.3949 753.63 26.8501L721.97 103.27L690.26 179.83C669.908 173.948 649.088 169.826 628.03 167.51V330.07L658.46 256.59C685.222 263.407 710.861 274.043 734.59 288.17L766.39 211.38L798.1 134.83C826.457 149.729 853.159 167.585 877.76 188.1L936.24 129.62C903.71 101.727 867.963 77.8208 829.76 58.4101Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M944.27 640.68L867.5 608.87C860.684 635.63 850.052 661.27 835.93 685L762.48 654.58L818.69 710.78L877.42 769.52C890.681 752.989 902.504 735.355 912.76 716.81L989.31 748.52C1002.13 724.159 1012.7 698.676 1020.88 672.39L944.27 640.68Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M628.07 1.97021V84.6702C614.49 83.4502 600.753 82.8336 586.86 82.8202C572.967 82.8069 559.23 83.4236 545.65 84.6702V330.07H628.07V167.5C649.128 169.816 669.948 173.938 690.3 179.82L722.01 103.26L753.67 26.8402C712.952 13.5447 670.782 5.19473 628.07 1.97021Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M628.07 330.07H545.65L469.51 361.61L411.23 419.88L379.69 496.02V578.44L411.23 654.58L469.51 712.86L545.65 744.4H628.07L704.21 712.86L762.48 654.58L794.02 578.44V496.02L762.48 419.88L704.21 361.61L628.07 330.07Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
        data-center
      />
      <path
        d="M628.07 744.41H545.65L469.51 712.87L439.09 786.31L407.28 863.09C450.046 886.728 497.078 901.643 545.65 906.97V823.89C572.985 827.797 600.736 827.797 628.07 823.89C638.314 822.438 648.472 820.435 658.5 817.89L628.07 744.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M877.76 188.1C853.187 167.59 826.516 149.734 798.19 134.83L766.43 211.41L734.63 288.2C710.902 274.073 685.262 263.437 658.5 256.62L628.07 330.1L704.21 361.64L760.41 305.44L819.15 246.7C840.659 263.919 860.201 283.461 877.42 304.97L935.99 246.41C918.375 225.26 898.886 205.744 877.76 188.1Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M956.59 578.41H794.02L762.48 654.55L835.93 684.97C850.052 661.24 860.684 635.6 867.5 608.84L944.27 640.65L1020.83 672.36C1030.34 641.773 1036.57 610.263 1039.42 578.36L956.59 578.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M355.03 363.68C348.82 371.961 343.055 380.566 337.76 389.46L260.98 357.66C247.652 381.781 237.063 407.316 229.41 433.79L306.19 465.59C303.645 475.622 301.642 485.783 300.19 496.03C296.283 523.361 296.283 551.109 300.19 578.44H379.67V496.03L411.21 419.88L355.03 363.68Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M411.23 654.58L355.03 710.78L296.29 769.52L237.76 828.09C255.374 849.247 274.867 868.766 296 886.41L354.57 827.84C371.105 841.077 388.739 852.88 407.28 863.12L439.09 786.34L469.51 712.9L411.23 654.58Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M935.99 246.41L877.42 304.97C860.201 283.461 840.659 263.919 819.15 246.7L760.41 305.41L704.21 361.61L762.48 419.88L835.93 389.46L912.71 357.66L989.26 325.95C974.354 297.635 956.498 270.974 935.99 246.41Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M439.09 288.17L407.28 211.41C364.524 235.046 326.813 266.832 296.28 304.97L237.76 246.41C217.25 270.983 199.395 297.655 184.49 325.98L261.04 357.69L337.76 389.46C343.045 380.567 348.8 371.961 355 363.68L411.2 419.88L469.48 361.61L439.09 288.17Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M989.26 748.52L912.76 716.81C902.519 735.353 890.713 752.987 877.47 769.52L818.74 710.78L762.53 654.58L704.26 712.86L760.46 769.06L819.2 827.8L877.76 886.41C898.902 868.778 918.408 849.272 936.04 828.13L994.52 886.61C1022.41 854.103 1046.32 818.384 1065.74 780.21L989.26 748.52Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M1020.83 402.08C1012.65 375.793 1002.09 350.31 989.26 325.95L912.76 357.66L835.98 389.46L762.53 419.88L794.07 496.03V578.41H873.56C878.935 540.762 876.897 502.426 867.56 465.56L944.33 433.76L1020.83 402.08Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M411.23 654.58L379.69 578.41H300.21C301.662 588.654 303.665 598.812 306.21 608.84L229.43 640.65C237.086 667.123 247.675 692.658 261 716.78L337.76 685C343.045 693.893 348.8 702.499 355 710.78L411.2 654.58H411.23Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M819.15 827.8L760.41 769.06L704.21 712.86L628.07 744.41L658.5 817.88C685.26 811.062 710.899 800.43 734.63 786.31L766.43 863.09L798.14 939.65C826.478 924.751 853.165 906.906 877.76 886.41L819.15 827.8Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <path
        d="M586.86 0.410156C572.993 0.410156 559.257 0.930154 545.65 1.97015C475.339 7.27404 406.775 26.4567 343.92 58.4102L375.57 134.84C399.932 122.019 425.414 111.451 451.7 103.27L483.41 179.83C456.937 187.489 431.402 198.082 407.28 211.41L439.09 288.2L469.51 361.64L545.65 330.1V84.6702C559.217 83.4502 572.953 82.8335 586.86 82.8202C600.767 82.8068 614.503 83.4235 628.07 84.6702V1.97015C614.47 0.930154 600.733 0.410156 586.86 0.410156Z"
        fill="white"
        stroke="#C3C1C4"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
    </>
  )
}
HomepageHeroPathsMobile.displayName = 'HomepageHeroPathsMobile'

export default HomepageHeroPathsMobile
