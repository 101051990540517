'use client'

import classnames from 'classnames'
import styles from './Header.module.scss'

const HeaderStatic = ({ item }: Pick<AccordionHeaderProps, 'item'>) => {
  return (
    <div className={classnames(styles.Header)}>{item.title && <div className={styles.title}>{item.title}</div>}</div>
  )
}

HeaderStatic.displayName = 'HeaderStatic'

export default HeaderStatic
