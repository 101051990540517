'use client'

import classnames from 'classnames'
import styles from './StandaloneMedia.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import MaskReveal from '@/components/MaskReveal/MaskReveal'
import FadeIn from '@/components/FadeIn/FadeIn'

const StandaloneMedia = ({ className, title, image }: SanityStandaloneMedia) => {
  if (!image) return null

  return (
    <div className={classnames(styles.StandaloneMedia, className)}>
      <div className={styles.inner}>
        {title && (
          <FadeIn type="fadeInUp">
            <div className={styles.titleContainer}>
              <h1 className={styles.titleContainer__title}>{title}</h1>
            </div>
          </FadeIn>
        )}
        <MaskReveal className={styles.imageContainer}>
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              tablet: {
                image,
                width: 1300,
              },
              mobile: {
                image,
                width: 800,
              },
              xs: {
                image,
                width: 500,
                options: {
                  height: 500,
                  fit: 'crop',
                },
              },
            }}
          />
        </MaskReveal>
      </div>
    </div>
  )
}

StandaloneMedia.displayName = 'StandaloneMedia'

export default StandaloneMedia
