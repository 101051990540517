import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import gsap from 'gsap'

export type LongArrowImperativeHandle = {
  animate: (isSmall: boolean) => void
}

type LongArrowProps = {
  className?: string
}

const LONG_VALUE = 0.5
const SMALL_VALUE = 10.5

const LongArrow = forwardRef<LongArrowImperativeHandle, LongArrowProps>(
  ({ className }: { className?: string }, ref) => {
    const $line = useRef<SVGPathElement>(null)
    const lineValue = useRef(LONG_VALUE)

    const animate = useCallback((isSmall: boolean, instant?: boolean) => {
      gsap.killTweensOf($line)
      gsap[instant ? 'set' : 'to'](lineValue, {
        current: isSmall ? SMALL_VALUE : LONG_VALUE,
        duration: 0.3,
        onUpdate: () => {
          const value = Math.round(lineValue.current)
          if ($line.current) {
            $line.current.setAttribute('d', `M${value} 6.50001L45 6.50001`)
          }
        },
      })
    }, [])

    useImperativeHandle(ref, () => ({
      animate: isSmall => {
        animate(isSmall)
      },
    }))

    return (
      <svg
        className={className}
        viewBox="0 0 46 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={$line}
          d={`M${SMALL_VALUE} 6.50001L45 6.50001`}
          stroke="currentColor"
        />
        <path
          d="M39.519 1.03664L45.001 6.51857L39.519 12.0005"
          stroke="currentColor"
        />
      </svg>
    )
  },
)

LongArrow.displayName = 'LongArrow'

export default LongArrow
