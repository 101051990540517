import { ReactNode, useEffect, useRef } from 'react'
import classnames from 'classnames'
import gsap from 'gsap'

import useInView from '@/hooks/use-in-view'
import useIsReducedMotion from '@/hooks/use-is-reduced-motion'

import styles from './MaskReveal.module.scss'
import useStore from '@/store'

type MaskRevealProps = {
  className?: string
  children: ReactNode
  direction?: 'FROM_TOP' | 'FROM_BOTTOM'
  delay?: number
  id?: string
}

const MaskReveal = ({ className, children, delay = 0, id }: MaskRevealProps) => {
  const { isInView, setElementToObserve } = useInView()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)
  const { isReducedMotion } = useIsReducedMotion()
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const hasAnimatedIn = useRef(false)

  useEffect(() => {
    if (!innerRef.current || !isInView || pageIsTransitioning) return
    if (hasAnimatedIn.current) return

    hasAnimatedIn.current = true

    gsap.killTweensOf(innerRef.current)

    const duration = isReducedMotion ? 0.0001 : 1.2
    const ease = 'Power3.easeInOut'

    gsap.to(innerRef.current, {
      scale: 1,
      opacity: 1,
      duration,
      ease,
      delay,
    })
  }, [isInView, delay, isReducedMotion, pageIsTransitioning])

  return (
    <div
      ref={ref => {
        setElementToObserve(ref)
        containerRef.current = ref
      }}
      id={id}
      className={classnames(styles.MaskReveal, className)}
    >
      <div
        ref={innerRef}
        className={styles.scaleContainer}
      >
        {children}
      </div>
    </div>
  )
}

MaskReveal.displayName = 'MaskReveal'

export default MaskReveal
