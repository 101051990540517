'use client'

import classnames from 'classnames'
import styles from './Header.module.scss'

const Header = ({ item, isOpen, toggleItem, index, headerId, accordionId, isHovering }: AccordionHeaderProps) => {
  return (
    <div className={classnames(styles.Header, { [styles.isOpen]: isOpen }, { [styles.isHovering]: isHovering })}>
      <button
        onClick={() => toggleItem(index)}
        aria-expanded={isOpen || false}
        className={classnames(styles.dropdownButton, isOpen && styles.dropdownButtonOpenState)}
        aria-controls={accordionId}
        id={headerId}
      >
        {item.title && (
          <div
            className={styles.title}
            data-accordion-title
          >
            {item.title}
          </div>
        )}
        <div className={classnames(styles.iconWrapper, isOpen && styles.iconWrapperOpenState)}>
          <span className={styles.lineVertical} />
          <span className={styles.lineHorizontal} />
        </div>
      </button>
    </div>
  )
}

Header.displayName = 'Header'

export default Header
