'use client'

import classnames from 'classnames'
import styles from './YellowQuote.module.scss'
import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import lottie, { AnimationItem } from 'lottie-web'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import FadeIn from '@/components/FadeIn/FadeIn'
import useBreakpoint from '@/hooks/use-breakpoint'
import useWindowResize from '@/hooks/use-window-resize'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const YellowQuote = ({ className, eyebrow, title, description }: SanityYellowQuote) => {
  const $bg = useRef<HTMLDivElement>(null)
  const $container = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<GSAPTimeline | null>(null)
  const scrolltriggerRef = useRef<ScrollTrigger | null>(null)
  const lottieAnimationRef = useRef<AnimationItem>()
  const $logoContainer = useRef<HTMLDivElement>(null)
  const { isMobile } = useBreakpoint()
  const resizeKey = useWindowResize()

  useEffect(() => {
    if (!$bg.current || !$container.current) return

    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    if (scrolltriggerRef.current) {
      scrolltriggerRef.current.kill()
    }

    timelineRef.current = gsap.timeline()

    timelineRef.current.fromTo(
      $bg.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
      },
    )

    const animateLogoContainer = (show: boolean) => {
      gsap.killTweensOf($logoContainer.current)
      gsap.to($logoContainer.current, {
        autoAlpha: show ? 1 : 0,
        onComplete: () => {
          if (!show) {
            if (lottieAnimationRef.current) {
              lottieAnimationRef.current.goToAndStop(0, true)
            }
          }
        },
      })
    }

    scrolltriggerRef.current = new ScrollTrigger({
      trigger: $bg.current,
      start: 'top top',
      end: `top+=${$bg.current.offsetHeight * 0.75} bottom`,
      animation: timelineRef.current,
      scrub: true,
      onEnterBack: () => {
        animateLogoContainer(false)
      },
      onLeave: () => {
        if (lottieAnimationRef.current) {
          animateLogoContainer(true)
          lottieAnimationRef.current.play()
        }
      },
    })
  }, [resizeKey])

  useEffect(() => {
    if (!$logoContainer.current && !lottieAnimationRef.current) return
    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy()
    }
    lottieAnimationRef.current = lottie.loadAnimation({
      container: $logoContainer.current as HTMLDivElement,
      renderer: 'svg',
      loop: false,
      path: '/lottie/yellow-quote-icon.json',
      autoplay: false,
    })
    return () => {
      if (lottieAnimationRef.current) {
        lottieAnimationRef.current.destroy()
      }
    }
  }, [])

  if (!title) return null

  return (
    <div
      className={classnames(styles.YellowQuote, className)}
      ref={$container}
    >
      <div
        className={styles.bg}
        ref={$bg}
      />
      <div className={styles.inner}>
        <div
          className={styles.logoContainer}
          ref={$logoContainer}
        />
        <div className={styles.content}>
          {eyebrow && (
            <FadeIn>
              <p className={styles.eyebrow}>{eyebrow}</p>
            </FadeIn>
          )}
          {isMobile ? (
            <FadeIn className={styles.titleContainer}>
              <h1 className={styles.title}>{title}</h1>
            </FadeIn>
          ) : (
            <AnimatedTextLines
              className={styles.titleContainer}
              querySelector="h1"
            >
              <h1 className={styles.title}>{title}</h1>
            </AnimatedTextLines>
          )}

          {description && (
            <FadeIn>
              <p className={styles.description}>{description}</p>
            </FadeIn>
          )}
        </div>
      </div>
    </div>
  )
}

YellowQuote.displayName = 'YellowQuote'

export default YellowQuote
