'use client'

import classnames from 'classnames'
import styles from './VimeoPlayer.module.scss'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import Player from '@vimeo/player'
import useInView from '@/hooks/use-in-view'

type VimeoPlayerType = {
  className?: string
  vimeoId: string
  mutedAndLooping: boolean
  fullWidth: boolean
  aspectRatio: string
}

const VimeoPlayer = ({ className, vimeoId, aspectRatio, mutedAndLooping }: VimeoPlayerType) => {
  const [id, setId] = useState<string | null>(null)
  // eslint-disable-next-line
  const player = useRef<any | null>(null)
  const { isInView, setElementToObserve } = useInView({ fireOnce: false })

  useEffect(() => {
    setId(`player_${Math.random()}`)
  }, [])

  useEffect(() => {
    if (!id) return

    if (player.current) {
      player.current.destroy()
    }

    player.current = new Player(id, {
      id: parseInt(vimeoId),
      width: 640,
      background: mutedAndLooping,
    })
  }, [vimeoId, mutedAndLooping, id])

  useEffect(() => {
    if (!player.current || !mutedAndLooping) return

    if (isInView) {
      player.current.play()
    } else {
      player.current.pause()
    }
  }, [isInView, mutedAndLooping, id])

  if (!vimeoId || !id) return null

  return (
    <div
      ref={ref => {
        setElementToObserve(ref)
      }}
      style={
        {
          '--aspect-ratio': aspectRatio,
        } as CSSProperties
      }
      className={classnames(styles.VimeoPlayer, className, { [styles.mutedAndLooping]: mutedAndLooping })}
    >
      <div
        id={id}
        className={styles.innerPlayer}
      />
    </div>
  )
}

VimeoPlayer.displayName = 'VimeoPlayer'

export default VimeoPlayer
