'use client'

import classnames from 'classnames'
import styles from './RichTextBlock.module.scss'
import RichTextBasic, { richTextBasicSerializer } from '@/components/RichTextBasic/RichTextBasic'
import { PortableTextReactComponents } from 'next-sanity'

/* eslint-disable */
const BLOCK = richTextBasicSerializer?.block as any
const NORMAL_BLOCK = BLOCK.normal as any
/* eslint-enable */

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  ...richTextBasicSerializer,
  block: {
    normal: NORMAL_BLOCK,
    h2: ({ children }) => <h2 className={styles.h2}>{children}</h2>,
    h3: ({ children }) => <h3 className={styles.h3}>{children}</h3>,
  },
}

const RichTextBlock = ({ className, title, eyebrow, subtitle, content }: SanityRichTextBlock) => {
  if (!content?.length || !title) return null

  return (
    <div className={classnames(styles.RichTextBlock, className)}>
      <div className={styles.inner}>
        <div className={styles.headerContent}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <h1 className={styles.title}>{title}</h1>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        <div className={styles.content}>
          <RichTextBasic
            content={content || []}
            serializer={portableTextSerializer}
          />
        </div>
      </div>
    </div>
  )
}

RichTextBlock.displayName = 'RichTextBlock'

export default RichTextBlock
