'use client'
import classnames from 'classnames'
import styles from './Content.module.scss'
import RichTextBasic from '@/components/RichTextBasic/RichTextBasic'

const Content = ({ item, headerId, accordionId }: AccordionContentProps) => {
  return (
    <div
      id={accordionId}
      className={classnames(styles.Content)}
      role="region"
      aria-labelledby={headerId}
    >
      <div className={styles.contentInnerWrapper}>
        <div className={styles.description}>
          <RichTextBasic content={item.description} />
        </div>
      </div>
    </div>
  )
}

Content.displayName = 'Content'

export default Content
