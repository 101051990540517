'use client'

import classnames from 'classnames'
import styles from './FadeIn.module.scss'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import useInView from '@/hooks/use-in-view'
import useStore from '@/store'

type FadeInProps = {
  className?: string
  children: ReactNode
  type?: 'fadeIn' | 'fadeInUp'
  delay?: number
}

const FadeIn = ({ className, children, type = 'fadeIn', delay }: FadeInProps) => {
  const $inner = useRef<HTMLDivElement | null>(null)
  const { setElementToObserve, isInView } = useInView({
    scrolltriggerStart: 'top+=50 bottom',
  })
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const hasAnimatedIn = useRef(false)

  const animate = useCallback(() => {
    if (!$inner.current) return
    gsap.killTweensOf($inner.current)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let gsapConfig: any = {}

    const duration = 0.9
    const ease = 'Power3.easeOut'

    if (type === 'fadeIn') {
      gsapConfig = {
        autoAlpha: 1,
      }
    } else if (type === 'fadeInUp') {
      gsapConfig = {
        autoAlpha: 1,
        y: 0,
      }
    }

    if (delay) gsapConfig.delay = delay
    gsapConfig.duration = duration
    gsapConfig.ease = ease

    gsap.to($inner.current, gsapConfig)
  }, [delay, type])

  useEffect(() => {
    if (pageIsTransitioning) return
    if (!isInView || hasAnimatedIn.current) return
    hasAnimatedIn.current = true
    animate()
  }, [isInView, animate, pageIsTransitioning])

  return (
    <div
      className={classnames(styles.FadeIn, className)}
      data-type={type}
    >
      <div
        ref={ref => {
          $inner.current = ref
          setElementToObserve(ref)
        }}
        className={styles.inner}
      >
        {children}
      </div>
    </div>
  )
}

FadeIn.displayName = 'FadeIn'

export default FadeIn
