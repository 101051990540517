'use client'

import classnames from 'classnames'
import styles from './ContactForm.module.scss'
import Button from '@/components/Button/Button'
import { Dispatch, SetStateAction, useCallback, useContext, useRef, useState } from 'react'
import axios from 'axios'
import ChevronDown from '@/components/_svgs/ChevronDown'
import { ScrollContext } from '@/context/Scroll'
import gsap from 'gsap'
import { HEADER_ID } from '@/components/Navigation/Navigation'
import FadeIn from '@/components/FadeIn/FadeIn'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Link from '@/components/Link/Link'
import { DOC_TYPES } from '@/data'

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

const DEFAULT_VALUES = {
  fullName: '',
  email: '',
  phoneNumber: '',
  reasonForContact: '',
  message: '',
  agreeToTerms: false,
}

type FormData = {
  fullName: string
  email: string
  phoneNumber: string
  reasonForContact: string
  message: string
  agreeToTerms: boolean
}

const ContactForm = ({
  className,
  eyebrow,
  title,
  description,
  smallText,
  successText,
  reasonDropdownOptions,
  fullWidth,
  reasonDropdownTitle,
  disableRecaptcha,
  isPdfRequest = false,
  pdfName,
}: SanityContactForm) => {
  const [formData, setFormData] = useState<FormData>(DEFAULT_VALUES)
  const [errors, setErrors] = useState<FormData>(DEFAULT_VALUES)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [sendingError, setSendingError] = useState('')
  const $container = useRef<HTMLDivElement | null>(null)
  const $successText = useRef<HTMLParagraphElement | null>(null)
  const $form = useRef<HTMLFormElement | null>(null)
  const $smallText = useRef<HTMLParagraphElement | null>(null)
  const { scroll } = useContext(ScrollContext)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [showSuccessText, setShowSuccessText] = useState(false)

  const successAnimation = useCallback(() => {
    if (isPdfRequest) {
      setShowSuccessText(true)
      return
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const $animateOutElements: any[] = [$form.current]
    if ($smallText.current) $animateOutElements.push($smallText.current)

    if (scroll && $container.current) {
      const headerHeight = document.getElementById(HEADER_ID)?.offsetHeight || 0
      scroll?.scrollTo($container.current, { duration: 0.8, offset: -headerHeight })
    }

    gsap.to($animateOutElements, {
      autoAlpha: 0,
      pointerEvents: 'none',
      onComplete: () => {
        gsap.to($successText.current, {
          autoAlpha: 1,
          pointerEvents: 'all',
        })
      },
    })
  }, [scroll, isPdfRequest])

  const identifyErrors = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorMap: any = { ...DEFAULT_VALUES }
    const _formData = { ...formData }
    Object.keys(errorMap).forEach(key => {
      const value = _formData[key as keyof typeof _formData]

      if (typeof value === 'boolean') return

      if (isPdfRequest && key === 'phoneNumber') return
      if (disableRecaptcha && key === 'agreeToTerms') return

      if (key === 'email') {
        const isValid = validateEmail(value)
        if (!isValid) errorMap.email = 'Please enter a valid email.'
      } else if (!value) {
        errorMap[key as keyof typeof errorMap] = 'This field is required.'
      }
    })

    setErrors(errorMap)

    return Object.values(errorMap).filter(err => err)
  }, [formData, disableRecaptcha, isPdfRequest])

  const handleSubmit = useCallback(async () => {
    if (!executeRecaptcha) return
    const errorList = identifyErrors()
    if (errorList.length) return
    setIsSubmitting(true)
    setSendingError('')
    const recaptchaToken = await executeRecaptcha('registerSubmit')
    axios
      .post('/api/contact-form', {
        ...formData,
        recaptchaToken,
        isPdfRequest,
        pdfName,
      })
      .then(_ => {
        setIsSuccess(true)
        setTimeout(
          () => {
            successAnimation()
          },
          isPdfRequest ? 800 : 400,
        )
      })
      .catch(err => {
        console.error(err)
        setSendingError('Something went wrong.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [identifyErrors, formData, successAnimation, executeRecaptcha, isPdfRequest, pdfName])

  return (
    <div
      className={classnames(
        styles.ContactForm,
        className,
        { [styles.fullWidth]: fullWidth },
        { [styles.isPdfRequest]: isPdfRequest },
      )}
      ref={$container}
    >
      <div className={styles.inner}>
        <div className={styles.textContent}>
          {eyebrow && (
            <FadeIn>
              <p className={styles.eyebrow}>{eyebrow}</p>
            </FadeIn>
          )}
          {title && (
            <FadeIn>
              <h1 className={styles.title}>{title}</h1>
            </FadeIn>
          )}
          {description && (
            <FadeIn>
              <p className={styles.description}>{description}</p>
            </FadeIn>
          )}
          {successText && (
            <p
              className={styles.successText}
              ref={$successText}
            >
              {successText}
            </p>
          )}
        </div>
        <FadeIn className={styles.formContainer}>
          <form
            ref={$form}
            className={styles.form}
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <Input
              name="fullName"
              label="Full Name"
              setFormData={setFormData}
              formData={formData}
              error={errors?.fullName}
              setErrors={setErrors}
            />
            <Input
              name="email"
              label="Email"
              setFormData={setFormData}
              formData={formData}
              error={errors?.email}
              setErrors={setErrors}
            />
            {!isPdfRequest && (
              <Input
                name="phoneNumber"
                label="Phone Number"
                setFormData={setFormData}
                formData={formData}
                error={errors?.phoneNumber}
                setErrors={setErrors}
              />
            )}
            {!!reasonDropdownOptions?.length && (
              <Select
                name="reasonForContact"
                label={reasonDropdownTitle || 'Reason for Contact'}
                formData={formData}
                error={errors?.reasonForContact}
                options={reasonDropdownOptions.map(value => ({ title: value, value }))}
                onChange={value => {
                  setErrors(prev => {
                    return {
                      ...prev,
                      reasonForContact: '',
                    }
                  })
                  const newValue = {
                    ...formData,
                    reasonForContact: value,
                  }
                  setFormData(newValue)
                }}
              />
            )}

            <div className={styles.textareaContainer}>
              <label
                htmlFor="message"
                className={styles.textAreaContainer__label}
              >
                Message
              </label>
              <textarea
                className={styles.textarea}
                name="message"
                id="message"
                onChange={e => {
                  setErrors(prev => {
                    return {
                      ...prev,
                      message: '',
                    }
                  })
                  const newValue = {
                    ...formData,
                    message: e.target.value,
                  }
                  setFormData(newValue)
                }}
              />
              {errors?.message && <p className={styles.error}>{errors?.message}</p>}
            </div>
            {!disableRecaptcha && (
              <label
                htmlFor="contactCheckbox"
                className={styles.checkboxLabel}
              >
                <input
                  type="checkbox"
                  id="contactCheckbox"
                  name="contactCheckbox"
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      agreeToTerms: Boolean(e.target.checked),
                    }))
                  }}
                />

                <span className={styles.checkboxLabel__text}>
                  By clicking submit, you agree to our{' '}
                  <Link
                    link={{
                      label: 'Privacy',
                      linkType: 'internal',
                      link: {
                        slug: 'privacy-policy',
                        _type: DOC_TYPES.PAGE,
                      },
                    }}
                  />{' '}
                  terms and those of reCAPTCHA.
                </span>
              </label>
            )}
            {showSuccessText ? (
              <p className={styles.simpleSuccessMessage}>
                Your request has been sent. We will be in contact as soon as possible.
              </p>
            ) : (
              <Button
                label={isSuccess ? 'Sent!' : isSubmitting ? 'Submitting...' : 'Submit'}
                className={styles.formSubmit}
                disabled={isSubmitting || isSuccess || (formData.agreeToTerms === false && !disableRecaptcha)}
              />
            )}
            {sendingError && <p className={styles.error}>{sendingError}</p>}
          </form>
        </FadeIn>

        {smallText && (
          <FadeIn className={styles.smallTextContainer}>
            <p
              ref={$smallText}
              className={styles.smallText}
            >
              {smallText}
            </p>
          </FadeIn>
        )}
      </div>
    </div>
  )
}

const Input = ({
  className,
  name,
  label,
  setFormData,
  setErrors,
  formData,
  error,
}: {
  className?: string
  name: string
  label: string
  formData: FormData
  error: string
  setErrors: Dispatch<SetStateAction<FormData>>
  setFormData: Dispatch<SetStateAction<FormData>>
}) => {
  return (
    <div
      className={classnames(
        styles.inputContainer,
        className,
        {
          [styles.hasValue]: formData[name as keyof typeof formData],
        },
        {
          [styles.hasError]: error,
        },
      )}
    >
      <input
        type="text"
        className={styles.input}
        id={name}
        name={name}
        onChange={e => {
          setErrors((prev: FormData) => {
            return {
              ...prev,
              [name]: '',
            }
          })

          if (!setFormData || !formData) return
          const newFormData = {
            ...formData,
            [name]: e.target.value,
          }
          setFormData(newFormData)
        }}
      />
      <label
        htmlFor={name}
        className={styles.label}
      >
        {label}
      </label>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

const Select = ({
  className,
  name,
  label,
  options,
  onChange,
  formData,
  error,
}: {
  className?: string
  name: string
  label: string
  options: { title: string; value: string }[]
  onChange: (value: string) => void
  formData: FormData
  error?: string
}) => {
  return (
    <div className={classnames(styles.selectContainer, className, { [styles.hasError]: error })}>
      <select
        className={classnames(styles.select, { [styles.hasValue]: formData?.reasonForContact !== '' })}
        name={name}
        id={name}
        onChange={e => {
          if (onChange) onChange(e.target.value)
        }}
      >
        <option value=""></option>
        {options.map((option, i) => (
          <option
            key={i}
            value={option.value}
          >
            {option.title}
          </option>
        ))}
      </select>
      <label
        htmlFor={name}
        className={styles.label}
      >
        {label}
      </label>
      <ChevronDown className={styles.chevronDown} />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

ContactForm.displayName = 'ContactForm'

export default ContactForm
