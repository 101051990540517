'use client'

import classnames from 'classnames'
import styles from './ThreeUpBlogPosts.module.scss'
import LongArrow from '@/components/_svgs/LongArrow'
import { useRef } from 'react'
import gsap from 'gsap'
import PaginatedBlogPostsCard from '@/sections/PaginatedBlogPosts/PaginatedBlogPostsCard/PaginatedBlogPostsCard'

const ThreeUpBlogPosts = ({ className, title, items, hasKeyline }: SanityThreeUpBlogPosts) => {
  const $listOuter = useRef<HTMLDivElement | null>(null)
  const $items = useRef<(HTMLLIElement | null)[]>([])

  const handleButtonClick = (direction: 'PREV' | 'NEXT') => {
    if (!$listOuter.current) return
    let leftOffset = $listOuter.current?.scrollLeft || 0
    const itemWidth = $items.current[0]?.offsetWidth || 0

    if (direction === 'NEXT') {
      leftOffset = leftOffset - itemWidth * 0.5
    }

    const newDistance =
      direction === 'PREV'
        ? gsap.utils.snap(itemWidth, leftOffset - itemWidth)
        : gsap.utils.snap(itemWidth, leftOffset + itemWidth) + 1

    gsap.killTweensOf($listOuter.current)
    gsap.to($listOuter.current, {
      scrollLeft: newDistance,
      duration: 0.2,
    })
  }

  if (!title || !items?.length) return null

  return (
    <div className={classnames(styles.ThreeUpBlogPosts, className)}>
      <div className={styles.inner}>
        {hasKeyline && (
          <div className={styles.keylineContainer}>
            <div className={styles.keyline} />
          </div>
        )}
        <div className={styles.header}>
          {title && <h1 className={styles.title}>{title}</h1>}
          <div className={styles.arrowContainer}>
            <button
              className={styles.arrowLeft}
              aria-label="show previous blog post"
              onClick={() => {
                handleButtonClick('PREV')
              }}
            >
              <LongArrow />
            </button>
            <button
              className={styles.arrowRight}
              aria-label="show next blog post"
              onClick={() => {
                handleButtonClick('NEXT')
              }}
            >
              <LongArrow />
            </button>
          </div>
        </div>
        <div
          className={styles.listOuter}
          ref={$listOuter}
        >
          <ul className={styles.list}>
            {items.map((item, i) => (
              <li
                key={i}
                className={styles.item}
                ref={ref => {
                  if (!ref) return
                  $items.current[i] = ref
                }}
              >
                <PaginatedBlogPostsCard
                  index={i}
                  {...item}
                  key={i}
                  activeFilter="anystring"
                  className={styles.card}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

ThreeUpBlogPosts.displayName = 'ThreeUpBlogPosts'

export default ThreeUpBlogPosts
