'use client'

import { Fragment, useState, type ElementType } from 'react'
import SectionContainer from '@/components/SectionContainer/SectionContainer'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { buildIdFromText } from '@/utils'

/* INJECT_SECTIONS_IMPORT */
import PaginatedBlogPosts from '@/sections/PaginatedBlogPosts/PaginatedBlogPosts'
import RichTextBlock from '@/sections/RichTextBlock/RichTextBlock'
import ThreeUpBlogPosts from '@/sections/ThreeUpBlogPosts/ThreeUpBlogPosts'
import ContactForm from '@/sections/ContactForm/ContactForm'
import TitleAndRichTextScroller from '@/sections/TitleAndRichTextScroller/TitleAndRichTextScroller'
import StandaloneMedia from '@/sections/StandaloneMedia/StandaloneMedia'
import RichTextWithIcon from '@/sections/RichTextWithIcon/RichTextWithIcon'
import Accordion from '@/sections/Accordion/Accordion'
import TeamMembers from '@/sections/TeamMembers/TeamMembers'
import TitleAndImageHero from '@/sections/TitleAndImageHero/TitleAndImageHero'
import YellowQuote from '@/sections/YellowQuote/YellowQuote'
import FourOhFour from '@/sections/FourOhFour/FourOhFour'
import HomepageHero from '@/sections/HomepageHero/HomepageHero'
import SideBySides from '@/sections/SideBySides/SideBySides'
import Footer from '@/components/Footer/Footer'
import BlogContent from '@/sections/BlogContent/BlogContent'

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({
  ignoreMobileResize: true,
})

const SECTIONS: {
  [key: string]: ElementType
} = {
  /* INJECT_SECTIONS_COMPONENT_TYPE */
  paginatedBlogPosts: PaginatedBlogPosts,
  richTextBlock: RichTextBlock,
  threeUpBlogPosts: ThreeUpBlogPosts,
  contactForm: ContactForm,
  titleAndRichTextScroller: TitleAndRichTextScroller,
  standaloneMedia: StandaloneMedia,
  richTextWithIcon: RichTextWithIcon,
  accordion: Accordion,
  teamMembers: TeamMembers,
  titleAndImageHero: TitleAndImageHero,
  yellowQuote: YellowQuote,
  fourOhFour: FourOhFour,
  homepageHero: HomepageHero,
  sideBySides: SideBySides,
  blogContent: BlogContent,
}

function Sections({ sections }: SectionsProps) {
  const sectionsFiltered = sections.filter(
    sectionArr => !sectionArr?.section[0]?.cmsSettings?.isHidden && sectionArr?.section,
  )
  const [allowSectionBackgroundChange, setAllowSectionBackgroundChange] = useState(false)

  if (!sectionsFiltered?.length) return null

  return (
    <>
      {sectionsFiltered.map((sectionArr, i) => {
        if (!sectionArr?.section) {
          console.warn('No sections tied to data ', sectionArr)
          return null
        }

        const prevSectionObj = sectionsFiltered[i - 1]?.section[0]
        const nextSectionObj = sectionsFiltered[i + 1]?.section[0]
        const sectionObj = sectionArr?.section[0]

        if (!sectionObj._type) {
          return null
        }

        if (!SECTIONS[sectionObj._type]) {
          console.warn('No section found with type ', sectionObj._type)
          return null
        }

        if (sectionObj?.cmsSettings?.isHidden) {
          return null
        }

        const SectionComponent = SECTIONS[sectionObj._type]

        /* eslint-disable */
        const prevSectionBackground = (prevSectionObj as any)?.sectionBackground
        const currentSectionBackground = (sectionObj as any)?.sectionBackground
        const nextSectionBackground = (nextSectionObj as any)?.sectionBackground
        const nextSectionType = (nextSectionObj as any)?._type || undefined
        const previousSectionType = (prevSectionObj as any)?._type || undefined
        /* eslint-enable */

        return (
          <Fragment key={`${i}_${sectionObj._id}_${buildIdFromText(sectionObj?.cmsSettings?.cmsTitle || '')}`}>
            <SectionContainer
              cmsTitle={sectionObj?.cmsSettings?.cmsTitle || ''}
              _type={sectionObj._type}
              sectionsLength={sectionsFiltered.length}
              sectionBackground={currentSectionBackground}
              previousSectionBackground={prevSectionBackground}
              nextSectionBackground={nextSectionBackground}
              nextSectionType={nextSectionType}
              previousSectionType={previousSectionType}
              sectionIndex={i}
              allowSectionBackgroundChange={allowSectionBackgroundChange}
              setAllowSectionBackgroundChange={setAllowSectionBackgroundChange}
            >
              <SectionComponent {...sectionObj} />
            </SectionContainer>
            {i === sectionsFiltered.length - 1 && (
              <SectionContainer
                sectionIndex={i + 1}
                cmsTitle={'footer'}
                _type={'footer'}
                sectionsLength={sectionsFiltered.length}
                sectionBackground={'dark'}
                previousSectionBackground={currentSectionBackground}
                nextSectionBackground={'light'}
                nextSectionType={undefined}
                previousSectionType={previousSectionType}
                allowSectionBackgroundChange={allowSectionBackgroundChange}
                setAllowSectionBackgroundChange={setAllowSectionBackgroundChange}
              >
                <Footer />
              </SectionContainer>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export default Sections
