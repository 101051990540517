'use client'

import classnames from 'classnames'
import styles from './SideBySidesItem.module.scss'
import RichTextBasic from '@/components/RichTextBasic/RichTextBasic'
import Button from '@/components/Button/Button'
import SanityImage from '@/components/SanityImage/SanityImage'
import { useEffect, useRef, useState } from 'react'
import lottie, { AnimationItem } from 'lottie-web'
import useInView from '@/hooks/use-in-view'
import { LOTTIE_SYMBOL_PATHS } from '@/data'
import MaskReveal from '@/components/MaskReveal/MaskReveal'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import FadeIn from '@/components/FadeIn/FadeIn'

type SanitySideBySidesItemProps = Pick<SanitySideBySides, 'imageOrientation'> &
  SanitySideBySidesItem & {
    className?: string
    isStacked: boolean
    itemLength: number
    isLast: boolean
  }

const SideBySidesItem = ({
  className,
  title,
  eyebrow,
  lottieIconAnimation,
  image,
  link,
  description,
  imageOrientation,
  isStacked,
  itemLength,
  isLast,
  imageCaption,
}: SanitySideBySidesItemProps) => {
  const hasDescription = description ? description?.length > 0 : false
  const hasLottie = lottieIconAnimation !== 'none'
  const $lottieContainer = useRef<HTMLDivElement>(null)
  const lottieAnimationRef = useRef<AnimationItem>()
  const { setElementToObserve, isInView } = useInView()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!$lottieContainer.current || lottieIconAnimation === 'none') return
    const animationPath = LOTTIE_SYMBOL_PATHS[lottieIconAnimation]

    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy()
    }

    lottieAnimationRef.current = lottie.loadAnimation({
      container: $lottieContainer.current as HTMLDivElement,
      renderer: 'svg',
      loop: false,
      path: animationPath,
      autoplay: false,
    })
    lottieAnimationRef.current.addEventListener('DOMLoaded', function () {
      setIsLoaded(true)
    })

    return () => {
      if (lottieAnimationRef.current) {
        lottieAnimationRef.current.destroy()
      }
    }
  }, [lottieIconAnimation])

  useEffect(() => {
    if (isInView && lottieAnimationRef.current && isLoaded) {
      lottieAnimationRef.current.play()
    }
  }, [isInView, isLoaded])

  if (!title) return null

  return (
    <div
      data-item-length={itemLength}
      className={classnames(
        styles.SideBySidesItem,
        className,
        {
          [styles.hasLottieAnimation]: hasLottie,
        },
        {
          [styles.isStacked]: isStacked,
        },
        {
          [styles.isLast]: isLast,
        },
      )}
      data-image-orientation={imageOrientation}
      ref={ref => {
        setElementToObserve(ref)
      }}
    >
      <div className={styles.inner}>
        <div className={styles.textContentGrid}>
          {hasLottie && (
            <div
              ref={$lottieContainer}
              className={styles.lottieContainer}
            />
          )}
          <div className={styles.textContent}>
            {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
            <AnimatedTextLines querySelector="h1">
              <h1 className={styles.title}>{title}</h1>
            </AnimatedTextLines>
            {hasDescription && (
              <FadeIn>
                <div className={styles.descriptionContainer}>
                  <RichTextBasic content={description || []} />
                </div>
              </FadeIn>
            )}
            {link && (
              <Button
                ariaLabel={`Learn more about "${title}"`}
                link={link}
                className={styles.button}
              />
            )}
          </div>
        </div>
        <div className={styles.imageContent}>
          <MaskReveal className={styles.imageContainer}>
            <SanityImage
              image={image}
              className={styles.image}
              breakpoints={{
                tablet: {
                  width: 800,
                  image: image,
                },
                mobile: {
                  width: 700,
                  image: image,
                },
                xs: {
                  width: 400,
                  image: image,
                },
              }}
            />
          </MaskReveal>
          {imageCaption && <p className={styles.imageCaption}>{imageCaption}</p>}
        </div>
      </div>
    </div>
  )
}

SideBySidesItem.displayName = 'SideBySidesItem'

export default SideBySidesItem
