'use client'

import classnames from 'classnames'
import styles from './SideBySides.module.scss'
import SideBySidesItem from '@/sections/SideBySides/SideBySidesItem/SideBySidesItem'
import FadeIn from '@/components/FadeIn/FadeIn'
import { useMemo } from 'react'
import useBreakpoint from '@/hooks/use-breakpoint'

const SideBySides = ({ className, title, isStacked, imageOrientation, items }: SanitySideBySides) => {
  items = useMemo(() => {
    return items.filter(item => !item.isHidden)
  }, [items])
  const { isMobile } = useBreakpoint()
  isStacked = useMemo(() => {
    return !isMobile && isStacked
  }, [isMobile, isStacked])

  if (!imageOrientation || !items?.length) return null

  return (
    <div className={classnames(styles.SideBySides, className, { [styles.isStacked]: isStacked })}>
      <div className={styles.inner}>
        {title && (
          <FadeIn type="fadeInUp">
            <h1 className={styles.title}>{title}</h1>
          </FadeIn>
        )}
        <div className={styles.items}>
          {items.map((item, i) => (
            <SideBySidesItem
              key={i}
              isHidden={item.isHidden}
              itemLength={items.length}
              title={item?.title}
              lottieIconAnimation={item.lottieIconAnimation}
              imageCaption={item.imageCaption}
              link={item.link}
              eyebrow={item.eyebrow}
              image={item?.image}
              description={item?.description}
              imageOrientation={imageOrientation}
              isStacked={isStacked}
              isLast={i === items.length - 1}
            />
          ))}
          {isStacked && <div className={styles.stickySpacer} />}
        </div>
      </div>
    </div>
  )
}

SideBySides.displayName = 'SideBySides'

export default SideBySides
