'use client'

import classnames from 'classnames'
import styles from './PaginatedBlogPosts.module.scss'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import AnimatedTextLines from '@/components/AnimatedTextLines/AnimatedTextLines'
import FadeIn from '@/components/FadeIn/FadeIn'
import PaginatedBlogPostsCard from '@/sections/PaginatedBlogPosts/PaginatedBlogPostsCard/PaginatedBlogPostsCard'
import { useSearchParams } from 'next/navigation'

function reduceArrayToUniqueItems(a: []) {
  return a.sort().filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1]
  })
}

export const ALL_TEXT = 'All'
const PER_PAGE = 6 // excluding the big item that takes up full space on desktop

const PaginatedBlogPosts = ({ className, title, description, items, enableFilters }: SanityPaginatedBlogPosts) => {
  items = useMemo(() => {
    return items.sort(function (a, b) {
      /* eslint-disable */
      const bDate = new Date(b.blogData?.publishedDate || b._createdAt) as any
      const aDate = new Date(a.blogData?.publishedDate || a._createdAt) as any
      return bDate - aDate
      /* eslint-enable */
    })
  }, [items])
  const [activeFilter, setActiveFilter] = useState(ALL_TEXT)
  const [posts, setPosts] = useState(items.slice(0, PER_PAGE))
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPosts, setTotalPosts] = useState(items.length)
  const filters = useMemo(() => {
    let _filters = items.map(item => item.blogData?.blogCategory)
    _filters = reduceArrayToUniqueItems(_filters as [])
    return _filters
  }, [items])
  const searchParams = useSearchParams()
  const searchParamsFilter = searchParams.get('filter') as string
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>()
  const defaultTitle = useRef<string | null>(null)

  useEffect(() => {
    if (!defaultTitle.current) defaultTitle.current = document.title
    const suffixText = defaultTitle.current.split('|')[1]
    const suffix = suffixText ? ` |${suffixText}` : ''
    let title = defaultTitle.current
    if (activeFilter && activeFilter !== ALL_TEXT) {
      title = `${activeFilter}${suffix}`
    }
    document.title = title
  }, [activeFilter])

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

    debounceTimeout.current = setTimeout(() => {
      if (activeFilter === ALL_TEXT && !searchParamsFilter) return
      if (activeFilter !== searchParamsFilter) {
        if (!activeFilter || !searchParamsFilter) {
          setActiveFilter(ALL_TEXT)
        } else {
          setActiveFilter(searchParamsFilter as string)
        }
        setCurrentPage(1)
      }
    }, 100)
  }, [searchParamsFilter, activeFilter])

  useEffect(() => {
    let newItems = [...items]
    let sliceNumber = PER_PAGE * currentPage
    if (activeFilter !== ALL_TEXT) {
      newItems = newItems.filter(item => item.blogData?.blogCategory === activeFilter)
    } else {
      sliceNumber = PER_PAGE * currentPage + 1
    }

    setTotalPosts(newItems.length)
    newItems = newItems.slice(0, sliceNumber).filter(item => item)
    setPosts(newItems)
  }, [activeFilter, items, currentPage])

  if (!title) return null

  return (
    <div className={classnames(styles.PaginatedBlogPosts, className, { [styles.noFilters]: !enableFilters })}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <AnimatedTextLines querySelector="h1">
            <h1 className={styles.header__title}>{title}</h1>
          </AnimatedTextLines>
          {description && (
            <FadeIn>
              <p className={styles.header__description}>{description}</p>
            </FadeIn>
          )}
        </div>
        <div className={styles.filters}>
          <div className={styles.filters__inner}>
            <p className={styles.filters__title}>Filters:</p>
            <ul className={styles.filters__list}>
              <li className={styles.filters__listItem}>
                <button
                  className={classnames(
                    styles.filters__button,
                    { [styles.activeFilter]: activeFilter === ALL_TEXT },
                    { [styles.notAll]: activeFilter !== ALL_TEXT },
                  )}
                  onClick={() => {
                    setActiveFilter(ALL_TEXT)
                    setCurrentPage(1)
                    window.history.pushState(null, '', '?')
                  }}
                >
                  {ALL_TEXT}
                </button>
              </li>
              {filters.map((filter, i) => (
                <li
                  className={styles.filters__listItem}
                  key={i}
                >
                  <button
                    onClick={() => {
                      setActiveFilter(filter as string)
                      setCurrentPage(1)
                      window.history.pushState(null, '', `?filter=${filter}`)
                    }}
                    className={classnames(
                      styles.filters__button,
                      { [styles.activeFilter]: activeFilter === filter },
                      { [styles.notAll]: activeFilter !== ALL_TEXT },
                    )}
                  >
                    {filter}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.items}>
          {posts.map((item, i) => (
            <PaginatedBlogPostsCard
              key={`${item.title}_${i}`}
              {...item}
              index={i}
              activeFilter={activeFilter}
            />
          ))}
        </div>
        {posts.length < totalPosts && (
          <div className={styles.loadMoreButtonContainer}>
            <button
              className={styles.loadMoreButton}
              onClick={() => {
                setCurrentPage(prev => prev + 1)
              }}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const DefaultExport = (props: SanityPaginatedBlogPosts) => {
  return (
    <Suspense>
      <PaginatedBlogPosts {...props} />
    </Suspense>
  )
}

PaginatedBlogPosts.displayName = 'PaginatedBlogPosts'

export default DefaultExport
