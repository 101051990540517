'use client'

import { PortableText, PortableTextReactComponents } from '@portabletext/react'

type BlogTitleRendererProps = SanityPage & {
  className?: string
}

const serializer: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
}

const BlogTitleRenderer = ({ title, blogData }: BlogTitleRendererProps) => {
  if (blogData?.styledTitle?.length) {
    return (
      <PortableText
        // eslint-disable-next-line
        value={blogData?.styledTitle as any}
        components={serializer}
      />
    )
  }

  return <>{title}</>
}

BlogTitleRenderer.displayName = 'BlogTitleRenderer'

export default BlogTitleRenderer
